/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react'
import {
  Route,
  Routes as ReactRoutes,
  BrowserRouter,
  useNavigate,
  Navigate,
} from 'react-router-dom'

import { getUserData, isAuthenticated } from '@/services/auth'

import Admin from '@/layouts/Admin'
import Recipe2BasketPage from '@/views/Recipe2Basket'
import ShoppableLinksPage from '@/views/ShoppableLinks'
import UserManagement from '@/views/UserManagement/UserManagement'

import Login from '@/views/Login'
import AuthRequest from '@/views/AuthRequest/AuthRequest'
import AuthCreate from '@/views/AuthCreate/AuthCreate'
import RecoveryPassword from '@/views/RecoveryPassword'
import CreateLink from './views/CreateLink/page'
import History from './views/History/page'

const ProtectedRoute = ({ allowRoles, children }) => {
  const navigate = useNavigate()

  const [isMounted, setIsMounted] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      isAuthenticated().then(hasAuth => {
        setIsLoggedIn(!!hasAuth)
        setIsLoading(false)
      })
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && !isLoading && !isLoggedIn) {
      navigate('/')
    }
  }, [isLoading, isLoggedIn, isMounted, navigate])

  useEffect(() => {
    if (isMounted && !isLoading && isLoggedIn) {
      ;(async function () {
        const { role } = await getUserData()

        if (!allowRoles.includes(role)) {
          navigate('/')
        }
      })()
    }
  }, [isLoading, isLoggedIn, isMounted, allowRoles, navigate])

  if (isLoading) {
    return null
  }

  return <Admin>{children}</Admin>
}

const LoginRoute = () => {
  const navigate = useNavigate()

  const [isMounted, setIsMounted] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      isAuthenticated().then(hasAuth => {
        setIsLoggedIn(!!hasAuth)
        setIsLoading(false)
      })
    }
  }, [isMounted])

  useEffect(() => {
    if (isMounted && !isLoading && isLoggedIn) {
      navigate('/admin/recipe2basket')
    }
  }, [isLoading, isLoggedIn, isMounted, navigate])

  if (isLoading || isLoggedIn) {
    return null
  }

  return <Login />
}

export function Routes() {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="/" element={<LoginRoute />} />
        <Route
          path="admin"
          element={<Navigate to="/admin/recipe2basket" replace />}
        />
        <Route
          path="admin/recipe2basket"
          element={
            <ProtectedRoute allowRoles={[0, 1, 2, 3]}>
              <Recipe2BasketPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="admin/shoppable-links"
          element={
            <ProtectedRoute allowRoles={[0, 1, 2, 3]}>
              <ShoppableLinksPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="admin/shoppable-links/create-shoppable-link"
          element={
            <ProtectedRoute allowRoles={[0, 1, 2, 3]}>
              <CreateLink />
            </ProtectedRoute>
          }
        />
        <Route
          path="admin/shoppable-links/links"
          element={
            <ProtectedRoute allowRoles={[0, 1, 2, 3]}>
              <History />
            </ProtectedRoute>
          }
        />
        <Route
          path="admin/user-management"
          element={
            <ProtectedRoute allowRoles={[0]}>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route path="auth-request" element={<AuthRequest />} />
        <Route path="auth-create" element={<AuthCreate />} />
        <Route path="recovery" element={<RecoveryPassword />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </ReactRoutes>
    </BrowserRouter>
  )
}
