import { useEffect, useState } from 'react'
import Card from '~/src/components/Card/Card'
import CardBody from '~/src/components/Card/CardBody'
import CardHeader from '~/src/components/Card/CardHeader'
import GridContainer from '~/src/components/Grid/GridContainer'
import { Button } from '@material-ui/core'
import { InfoButton } from '~/src/components/InfoButton'
import { ContainerIngredients, Fieldset, Input } from './style'
import { usePartner } from '~/src/contexts/PartnerContext'
import { Autocomplete } from '@material-ui/lab'
import {
  createPowerLink,
  useSearchRecipe,
  useSearchRecipes,
} from '~/src/services/powerlinks'
import { useDebounce } from '~/src/helpers/useDebouce'
import ModalResponse from '~/src/components/ModalResponse'
import { SearchRecipesResponse } from '~/src/@types/powerlinks'
import { useToast } from '~/src/contexts/Toast/context'

const CreateLink = () => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalStatus, setModalStatus] = useState<'success' | 'error' | null>(
    null
  )
  const [recipeId, setRecipeId] = useState<number>(0)
  const [name, setName] = useState<string>('')
  const [autocompleteValue, setAutocompleteValue] =
    useState<SearchRecipesResponse | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debounceVal, setDebounceVal] = useState<string>('')
  const [slug, setSlug] = useState<string>('')

  const { partner } = usePartner()
  const { showToast } = useToast()

  const debouce = useDebounce(searchTerm, 500)

  const { data: searchRecipes, isLoading: searchRecipesLoading } =
    useSearchRecipes({
      blogId: partner?.id,
      recipeUrl: debouce,
    })

  const { data: searchRecipe, isLoading: searchRecipeLoading } =
    useSearchRecipe({
      blogId: partner?.id,
      recipeId: recipeId,
    })

  function onSubmit() {
    if (searchRecipe) {
      const shoppableLink = {
        blogId: partner?.id,
        name: name,
        recipeUrl: debounceVal,
        ingredients: searchRecipe?.map(recipe => ({
          ingredient: recipe.ingredient,
          ingredientZaplyId: recipe.ingredientZaplyId,
          isPrincipal: recipe.isPrincipal,
        })),
      }

      createPowerLink(shoppableLink)
        .then(({ slug }) => {
          setSlug(slug)
          setModalStatus('success')
          setOpenModal(true)
        })
        .catch(() => {
          setModalStatus('error')
          setOpenModal(true)
        })
    }
  }

  function clearForm() {
    setRecipeId(0)
    setName('')
    setSearchTerm('')
    setDebounceVal('')
    setSlug('')
    setOpenModal(false)
    setModalStatus(null)
    setAutocompleteValue(null)
  }

  useEffect(() => {
    if (searchTerm) {
      const recipe = searchRecipes?.filter(recipe => recipe.url === searchTerm)
      if (recipe) {
        setRecipeId(recipe?.[0]?.id)
        setName(recipe?.[0]?.name.trim())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, searchRecipes])

  useEffect(() => {
    setDebounceVal(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouce])

  useEffect(() => {
    if (!!debouce && !searchRecipesLoading && searchRecipes?.length === 0) {
      showToast({
        title: 'Erro',
        content: `Não encontramos este conteúdo publicado.
          Verificar a url digitada.`,
        style: 'error',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouce, searchRecipesLoading, searchRecipes])

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success">
          <h4>Gerador de Shoppable Links</h4>
          <InfoButton
            title="Gerador de Shoppable Links"
            description="Crie seus Shoppable Links a partir de conteúdos previamente publicados em seu hub."
          />
        </CardHeader>
        <CardBody>
          <form>
            <Fieldset>
              <Autocomplete
                id="asynchronous"
                getOptionLabel={option => option.url}
                options={searchRecipes || []}
                loading={searchRecipesLoading}
                value={autocompleteValue}
                onChange={(_, newValue) => setAutocompleteValue(newValue)}
                onInputChange={(_, url) => setSearchTerm(url)}
                renderInput={params => (
                  <Input
                    {...params}
                    placeholder="Digitar URL de conteúdo de origem"
                    variant="standard"
                  />
                )}
              />
            </Fieldset>
            <Button
              style={{ marginTop: '15px' }}
              variant="contained"
              color="primary"
              type="button"
              onClick={onSubmit}
            >
              Criar Shoppable Link
            </Button>
          </form>
          {!searchRecipeLoading && recipeId && searchRecipe ? (
            <ContainerIngredients>
              <h5>Ingredientes</h5>
              <div>
                {searchRecipe?.map((recipe, index) => (
                  <p key={index}> - {recipe.ingredient}</p>
                ))}
              </div>
            </ContainerIngredients>
          ) : null}
        </CardBody>
      </Card>
      <ModalResponse
        open={openModal}
        onClose={clearForm}
        title={name}
        link={slug}
        modalStatus={modalStatus}
      />
    </GridContainer>
  )
}

export default CreateLink
