import { clsx } from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useUtilityStyles } from '@/styles/utility'

const useStyles = makeStyles(theme => ({
  root: {
    'minHeight': '250px',
    '& p': {
      marginTop: theme.spacing(2),
    },
  },
}))

export const LoadingStep = () => {
  const classes = useStyles()
  const utilityClasses = useUtilityStyles()

  return (
    <div
      className={clsx(
        classes.root,
        utilityClasses.flex,
        utilityClasses.flexColumn,
        utilityClasses.alignCenter,
        utilityClasses.justifyCenter
      )}
    >
      <CircularProgress />
      <Typography color="textSecondary">
        Carregando, por favor aguarde.
      </Typography>
    </div>
  )
}
