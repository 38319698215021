/* eslint-disable react/prop-types */

import { parseDataDay, parseDataHour } from './dataParser.js'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

export default function ChartLine(props) {
  let data = []

  if (props.eventGroup === 'date') {
    data = parseDataDay(props.data, props.field, props.fieldName)
  } else if (props.eventGroup === 'hour') {
    data = parseDataHour(props.data, props.field, props.fieldName)
  }
  return (
    <div style={{ width: props.style.width, height: props.style.height }}>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 20, right: 20, left: 30, bottom: 0 }}
        >
          <XAxis dataKey="name" />
          <YAxis width={30} />
          <Tooltip />
          <Line
            type="monotone"
            dataKey={props.fieldName}
            stroke="#8884d8"
            activeDot={{ r: 8 }}
            strokeWidth={3}
            isAnimationActive={true}
            animationEasing={'ease'}
            animationBegin={500}
            animationDuration={1000}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
