import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import styles from '@/assets/jss/material-dashboard-react/components/tableStyle.js'

const TableSortLabelAlternative = withStyles({
  active: {
    color: 'rgb(76, 175, 80)!important',
  },
})(TableSortLabel)

const useStyles = makeStyles(styles)

export default function CustomTable({
  tableHead,
  tableData,
  tableSort,
  tableHeaderColor,
  orderBy,
  sort,
  onClick,
  disableSort,
}) {
  const classes = useStyles()

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {!!tableHead && (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map(prop => (
                <TableCell
                  key={prop}
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                >
                  {disableSort ? (
                    prop
                  ) : (
                    <TableSortLabelAlternative
                      active={prop === orderBy}
                      direction={sort}
                      onClick={() =>
                        onClick({
                          orderBy:
                            tableSort.find(i => i.key === prop)?.value || prop,
                          sort: sort === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {prop}
                    </TableSortLabelAlternative>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {tableData.map((prop, index) => (
            <TableRow key={index} hover className={classes.tableBodyRow}>
              {prop.map((prop, index) => (
                <TableCell
                  key={prop}
                  className={classes.tableCell}
                  style={{ maxWidth: index === 0 ? '20rem' : '100%' }}
                >
                  {prop}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

CustomTable.defaultProps = {
  tableHeaderColor: 'gray',
  disableSort: false,
}

CustomTable.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  tableSort: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  orderBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disableSort: PropTypes.bool,
}
