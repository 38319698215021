import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import WarningIcon from '@material-ui/icons/WarningRounded'

export const InvalidTokenStep = ({ onClick }) => {
  const classes = useStyles()

  const handleClick = () => {
    onClick()
  }

  return (
    <>
      <div className={classes.icon}>
        <WarningIcon fontSize="large" color="inherit" />
      </div>

      <div className={classes.description}>
        <Typography component="span" variant="h6">
          Token inválido
        </Typography>
        <Typography color="textSecondary">
          O token fornecido não é válido, tente novamente pressionando o botão
          abaixo.
        </Typography>
      </div>

      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={handleClick}
        fullWidth
      >
        Tentar novamente
      </Button>
    </>
  )
}

InvalidTokenStep.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  icon: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  description: {
    'marginTop': theme.spacing(2),
    'marginBottom': theme.spacing(2),
    'textAlign': 'center',
    '& p': {
      marginTop: theme.spacing(1),
    },
  },
}))
