import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'
import { ToastContextProps, ToastProps } from './types'
import Toast from './toast'

const ToastContext = createContext<ToastContextProps | undefined>(undefined)

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const TIME_TO_CLOSE_TOAST = 3000
  const [toast, setToast] = useState<ToastProps | null>(null)

  const showToast = useCallback((toast: ToastProps) => {
    setToast(toast)
  }, [])

  useEffect(() => {
    if (toast) {
      const timer = setTimeout(() => setToast(null), TIME_TO_CLOSE_TOAST)
      return () => clearTimeout(timer)
    }
    return undefined
  }, [toast])

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && <Toast {...toast} />}
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider')
  }
  return context
}
