import styled from 'styled-components'
import { Alert as MuiAlert } from '@material-ui/lab'

export const Alert = styled(MuiAlert)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  max-width: 350px;
`
