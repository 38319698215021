import { Powerlink, UpdatePowerlink } from '~/src/@types/powerlinks'

export enum Sorted {
  asc = 'asc',
  desc = 'desc',
}

export type TableSort = {
  key: string
  value: string
}

export type TableSortLabel = {
  orderBy: string
  sort: Sorted
}

export type ITableHeader = {
  id: string
  label: string
  sort: boolean
}

export type ITable = {
  data: Powerlink[] | undefined
  sort: Sorted
  page: number
  limit: number
  total: number
  blogId: number
  tableHeader: ITableHeader[]
  sortHandler: (id: string) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangePage: (event: unknown, newPage: number) => void
  handleUpdateStatusPowerlink: (data: UpdatePowerlink) => void
}
