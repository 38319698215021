import { LoadingStep } from './loading'
import { ResetStep } from './reset'
import { SentStep } from './sent'
import { InvalidTokenStep } from './invalid-token'
import { ValidTokenStep } from './valid-token'
import { UpdatedStep } from './updated'

export const STEP = {
  LOADING: 'loading',
  RESET: 'reset',
  SENT: 'sended',
  INVALID_TOKEN: 'invalid-token',
  VALID_TOKEN: 'valid-token',
  UPDATED: 'updated',
}

export const STEPS = {
  [STEP.LOADING]: LoadingStep,
  [STEP.RESET]: ResetStep,
  [STEP.SENT]: SentStep,
  [STEP.INVALID_TOKEN]: InvalidTokenStep,
  [STEP.VALID_TOKEN]: ValidTokenStep,
  [STEP.UPDATED]: UpdatedStep,
}
