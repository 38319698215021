/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Dialog from '@material-ui/core/Dialog'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import PtLocale from 'date-fns/locale/pt-BR'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import ModalTitle from '@/components/Modal/ModalTitle'

export default function SimpleDialog(props) {
  const { onSend, onClose, open } = props
  const [selectedInitDate, setInitDate] = useState(new Date())
  const [selectedEndDate, setEndDate] = useState(new Date())

  const handleSend = () => {
    onSend({ selectedInitDate, selectedEndDate })
  }

  const handleClose = () => {
    onClose({ selectedInitDate, selectedEndDate })
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <ModalTitle id="customized-dialog-title" onClose={handleClose}>
        Insira a data de ínicio e fim
      </ModalTitle>

      <DialogContent dividers>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={PtLocale}>
          <KeyboardDatePicker
            disableFuture
            format="dd/MM/yyyy"
            style={{ marginRight: '10px' }}
            label="Data de ínico"
            value={selectedInitDate}
            onChange={setInitDate}
          />
          <KeyboardDatePicker
            disableFuture
            format="dd/MM/yyyy"
            label="Data final"
            value={selectedEndDate}
            onChange={setEndDate}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>

      <DialogActions>
        <Button
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          color="primary"
          disabled={moment(selectedEndDate).isAfter(moment(new Date()))}
          onClick={handleSend}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
