/* eslint-disable react/prop-types */

import { funnelData } from './dataParser.js'
import {
  FunnelChart,
  // Tooltip,
  ResponsiveContainer,
  Funnel,
  LabelList,
  Cell,
} from 'recharts'

export default function ChartLine(props) {
  const data = funnelData(props.data)
  return (
    <div style={{ width: props.style.width, height: props.style.height }}>
      <ResponsiveContainer>
        <FunnelChart margin={{ top: 20, right: 60, left: 20, bottom: 0 }}>
          {/* <Tooltip
            formatter={(value, name, props) => {
              if (props.payload.taxesValue) {
                return [props.payload.taxesValue, props.payload.taxesName]
              } else {
                return 'sem taxa'
              }
            }}
          /> */}
          <Funnel isAnimationActive={false} dataKey="rowSize" data={data}>
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} />
            ))}
            <LabelList
              position="right"
              fill="#000"
              stroke="none"
              dataKey="name"
            />
            <LabelList
              style={{ fontSize: '1.2rem' }}
              position="center"
              fill="#FFF"
              stroke="none"
              dataKey="value"
            />
          </Funnel>
        </FunnelChart>
      </ResponsiveContainer>
    </div>
  )
}
