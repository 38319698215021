import { mainApi } from './index'
import { UserReportMonthly } from '../@types/user'

const updateMonthlyReportUser = async (data: UserReportMonthly) => {
  const response = await mainApi.patch(`blog-users/${data.userId}/update`, {
    blogId: data.blogId,
    monthlyReport: data.monthlyReport,
  })
  return response.data
}

export { updateMonthlyReportUser }
