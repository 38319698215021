import {
  drawerWidth,
  transition,
  container,
} from '@/assets/jss/material-dashboard-react.js'

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
    height: 'calc(100vh - 165px)',
    overflow: 'auto',
  },
  container,
  padding: '0 15px',
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
  map: {
    marginTop: '70px',
  },
})

export default appStyle
