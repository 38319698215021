import { Alert } from './style'
import { AlertTitle } from '@material-ui/lab'
import { ToastProps } from './types'

const Toast: React.FC<ToastProps> = ({ style, title, content }) => (
  <Alert severity={style}>
    <AlertTitle>{title}</AlertTitle>
    {content}
  </Alert>
)

export default Toast
