import React, { useEffect, useState } from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import Card from '~/src/components/Card/Card'
import CardHeader from '~/src/components/Card/CardHeader'
import GridContainer from '~/src/components/Grid/GridContainer'
import { updatePowerlink, usePowerLinks } from '~/src/services/powerlinks'
import { InfoButton } from '~/src/components/InfoButton'
import styles from '@/assets/jss/material-dashboard-react/views/dashboardStyle.js'
import CardBody from '~/src/components/Card/CardBody'
import { Sorted } from './types'
import Table from './Table'
import { tableHeader } from './utils'
import { usePartner } from '~/src/contexts/PartnerContext'
import { useDebounce } from '~/src/helpers/useDebouce'
import { useMutation } from '@tanstack/react-query'
import { UpdatePowerlink } from '~/src/@types/powerlinks'
import { queryClient } from '~/src/services'
import { FilterContainer } from './style'
import { useToast } from '~/src/contexts/Toast/context'

const useStyles = makeStyles(styles)

const History: React.FC = () => {
  const { cardTitleWhite } = useStyles()
  const { partner } = usePartner()
  const { showToast } = useToast()

  const [order, setOrder] = useState<string>('blog')
  const [sort, setSort] = useState<Sorted>(Sorted.desc)
  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [debounceVal, setDebounceVal] = useState<string>('')
  const [isActive, setIsActive] = useState<boolean | null>(true)

  const debouce = useDebounce(searchTerm, 500)

  const { data } = usePowerLinks({
    limit: limit,
    page: page,
    sort: sort,
    order: order !== 'blog' ? `${order}` : `url`,
    blogId: partner?.id,
    searchTerm: debounceVal,
  })

  const { mutate } = useMutation({
    mutationFn: updatePowerlink,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['powerlinks'] })
      showToast({
        title: 'Sucesso',
        style: 'success',
        content: isActive
          ? 'Shoppable Link ativado'
          : 'Shoppable Link desativado. Seus usuários não poderão mais navegar através deste link',
      })
      setIsActive(null)
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['powerlinks'] })
      showToast({
        title: 'Erro',
        content: 'Erro ao atualizar o status do Shoppable Link',
        style: 'error',
      })
      setIsActive(null)
    },
  })

  function handleUpdatePowerlink(data: UpdatePowerlink) {
    setIsActive(data.active)
    mutate(data)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLimit(parseInt(event.target.value, 10))
    setPage(1)
  }

  const sortHandler = (id: string) => {
    const isAsc = order === id && sort === Sorted.asc
    setSort(isAsc ? Sorted.desc : Sorted.asc)
    setOrder(id)
  }

  const handleChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    setDebounceVal(searchTerm)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouce])

  return (
    <GridContainer>
      <Card>
        <CardHeader color="success">
          <h4 className={cardTitleWhite}>Meus links</h4>
          <InfoButton
            title="Meus links"
            description="Aqui você encontra todos seus Shoppable Links criados, ativos e inativos"
          />
        </CardHeader>
        <CardBody>
          <FilterContainer>
            <TextField
              id="search"
              label="Pesquisar"
              variant="standard"
              style={{ width: '400px' }}
              value={searchTerm}
              onChange={handleChangeTerm}
            />
          </FilterContainer>
          <Table
            data={data?.shoppableLinks}
            sort={sort}
            limit={limit}
            total={data?.total || 0}
            page={page}
            blogId={partner?.id}
            tableHeader={tableHeader}
            sortHandler={sortHandler}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleUpdateStatusPowerlink={handleUpdatePowerlink}
          />
        </CardBody>
      </Card>
    </GridContainer>
  )
}

export default History
