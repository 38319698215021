import styled from 'styled-components'

export const Title = styled.h4`
  margin: 10px auto;
  text-align: center;
`

export const Body = styled.div`
  padding: 0 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  color: black;

  .MuiTextField-root {
    margin: 10px 0;
  }
`

export const Loader = styled.div`
  text-align: center;
`

export const Error = styled.p`
  color: red;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`
