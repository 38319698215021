import {
  DialogContent,
  DialogTitle,
  Button as ButtonMUI,
  DialogActions,
  Dialog,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { SuccessIcon, ErrorIcon } from '~/src/assets/icons'

const Modal = styled(Dialog)``

const ModalHeader = styled(DialogTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  > p {
    text-align: left;
    font-size: 16px;

    > span {
      font-weight: bold;
    }
  }
`

const ModalFooter = styled(DialogActions)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    background-color: #fff;
    border: none;
    cursor: pointer;
    max-width: 40px;

    &:hover {
      background-color: none;
    }
  }
`

const Link = styled(NavLink)`
  font-size: 16px;
  text-align: left;
  margin-top: 16px;
  color: #000;
  text-decoration: underline;
  padding: 0;
  margin: 0;

  &:hover {
    color: #303f9f;
  }
`

const IconSuccess = styled(SuccessIcon)`
  margin: 0 auto;
  width: 40px;
  height: 40px;
`

const IconError = styled(ErrorIcon)`
  margin: 0 auto;
  width: 40px;
  height: 40px;
`

const Button = styled(ButtonMUI)`
  background-color: #303f9f;
  color: #fff;
  width: 100%;

  &:hover {
    background-color: #1f2969;
  }
`

const IconClipboard = styled(FileCopyIcon)`
  cursor: pointer;
  margin-left: 5px;
  color: #303f9f;
  background-color: none;
`

const PoppoverContent = styled.p`
  color: #000;
  border: 1px solid #303f9f;
  padding: 5px;
  border-radius: 5px;
  background-color: #fff;
`

export {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Link,
  IconSuccess,
  IconError,
  IconClipboard,
  Button,
  LinkContainer,
  PoppoverContent,
}
