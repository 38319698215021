import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { clsx } from 'clsx'

import { mainApi as api } from '@/services/api'

import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

import { ZaplyLogo } from '@/assets/icons'

import { useUtilityStyles } from '@/styles/utility'

import { STEP, STEPS } from './steps'

export default function RecoveryPassword() {
  const navigate = useNavigate()

  const classes = useStyles()
  const utilityClasses = useUtilityStyles()

  const [hash, setHash] = useState('')
  const [email, setEmail] = useState('')

  const [step, setStep] = useState(STEP.LOADING)

  useEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search)
    const hash = encodeURIComponent(urlQuery.get('sh'))

    if (hash && hash !== 'null') {
      setHash(hash)
    }
  }, [])

  useEffect(() => {
    if (hash) {
      const handleSuccess = res => {
        if (!res.data) return
        const hasStep = res.data in STEPS
        setStep(hasStep ? res.data : STEP.VALID_TOKEN)
      }

      const handleError = () => {
        setStep(STEP.INVALID_TOKEN)
      }

      api
        .get(`/blog-users/password/?sh=${hash}`)
        .then(handleSuccess)
        .catch(handleError)
    } else {
      const id = window.setTimeout(() => {
        setStep(STEP.RESET)
      }, 1500)

      return () => {
        window.clearTimeout(id)
      }
    }
  }, [hash])

  const getStepProps = step => {
    const stepProps = {
      [STEP.RESET]: {
        onChangeEmail: email => setEmail(email),
        onSubmitForm: () => setStep(STEP.SENT),
      },
      [STEP.SENT]: {
        email,
        onClick: () => navigate('/'),
      },
      [STEP.INVALID_TOKEN]: {
        onClick: () => setStep(STEP.RESET),
      },
      [STEP.VALID_TOKEN]: {
        hash,
        onSubmitForm: () => setStep(STEP.UPDATED),
      },
      [STEP.UPDATED]: {
        onClick: () => navigate('/'),
      },
    }

    return stepProps[step]
  }

  const renderStep = step => {
    const Step = STEPS[step]
    const props = getStepProps(step)

    return <Step {...props} />
  }

  return (
    <div
      className={clsx(classes.root, utilityClasses.flex, utilityClasses.center)}
    >
      <div className={classes.inner}>
        <div
          className={clsx(
            classes.logo,
            utilityClasses.flex,
            utilityClasses.center
          )}
        >
          <ZaplyLogo />
        </div>

        <Paper classes={{ root: classes.content }}>{renderStep(step)}</Paper>

        {step !== STEP.UPDATED && (
          <div
            className={clsx(
              classes.links,
              utilityClasses.flex,
              utilityClasses.spaceBetween
            )}
          >
            <Link to="/admin" component={NavLink}>
              Ir ao painel
            </Link>

            <Link to="/" component={NavLink}>
              Entrar
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.grey[100],
    overflow: 'auto',
  },
  inner: {
    width: '100%',
    maxWidth: '420px',
  },
  logo: {
    '& > svg': {
      height: '42px',
    },
  },
  content: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  links: {
    '& > a': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}))
