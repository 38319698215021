import { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { mainApi as api } from '@/services/api'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import LockIcon from '@material-ui/icons/LockRounded'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export const ValidTokenStep = ({ hash, onSubmitForm }) => {
  const classes = useStyles()

  const passwordRef = useRef(null)
  const passwordConfirmRef = useRef(null)

  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        password: values.password,
        sh: hash,
      }

      api
        .patch('/blog-users/password', payload)
        .then(() => {
          setSubmitting(false)
          onSubmitForm()
        })
        .catch(console.error)
    },
  })

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus()
    }
  }, [])

  return (
    <>
      <div className={classes.description}>
        <Typography component="span" variant="h6">
          Criar nova senha
        </Typography>
        <Typography color="textSecondary">
          Sua nova senha deve ser diferente das anteriormente utilizadas.
        </Typography>
      </div>

      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.marginTop}
      >
        <FormControl
          fullWidth
          margin="none"
          className={classes.marginTop}
          error={!!formik.touched.password && !!formik.errors.password}
        >
          <InputLabel variant="outlined">Nova senha</InputLabel>
          <OutlinedInput
            {...formik.getFieldProps('password')}
            inputRef={passwordRef}
            type={showPassword ? 'text' : 'password'}
            label="Nova senha"
            variant="outlined"
            startAdornment={
              <InputAdornment position="start">
                <Icon aria-hidden color="primary">
                  <LockIcon />
                </Icon>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    showPassword ? 'Ocultar a senha' : 'Exibir a senha'
                  }
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!!formik.touched.password && !!formik.errors.password && (
            <FormHelperText error>{formik.errors.password}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          margin="none"
          className={classes.marginTop}
          error={
            !!formik.touched.passwordConfirm && !!formik.errors.passwordConfirm
          }
        >
          <InputLabel variant="outlined">Confirme a nova senha</InputLabel>
          <OutlinedInput
            {...formik.getFieldProps('passwordConfirm')}
            inputRef={passwordConfirmRef}
            type={showPassword ? 'text' : 'password'}
            label="Confirme a nova senha"
            variant="outlined"
            startAdornment={
              <InputAdornment position="start">
                <Icon aria-hidden color="primary">
                  <LockIcon />
                </Icon>
              </InputAdornment>
            }
          />
          {!!formik.touched.passwordConfirm &&
            !!formik.errors.passwordConfirm && (
              <FormHelperText error>
                {formik.errors.passwordConfirm}
              </FormHelperText>
            )}
        </FormControl>

        <Button
          fullWidth
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          className={classes.marginTop}
          disabled={!formik.isValid || formik.isSubmitting}
          startIcon={
            formik.isSubmitting && (
              <CircularProgress size="1em" color="inherit" />
            )
          }
        >
          Redefinir senha
        </Button>
      </form>
    </>
  )
}

ValidTokenStep.propTypes = {
  hash: PropTypes.string.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
}

const noLeadingOrTrailingSpacesPattern = /^(?!\s).+(?<!\s)$/

const validationSchema = Yup.object({
  password: Yup.string()
    .required('A senha é obrigatória')
    .min(6, 'A senha deve ter ao menos 6 caracteres')
    .matches(
      noLeadingOrTrailingSpacesPattern,
      'A senha não deve conter espaço no início ou final'
    ),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais')
    .required('A confirmação da senha é obrigatória'),
})

const useStyles = makeStyles(theme => ({
  icon: {
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  description: {
    'textAlign': 'center',
    '& p': {
      marginTop: theme.spacing(1),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))
