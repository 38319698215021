import React from 'react'
import clsx from 'clsx'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import Poppers from '@material-ui/core/Popper'
// @material-ui/icons
import Person from '@material-ui/icons/Person'
// core components
import Button from '@/components/CustomButtons/Button.js'

import styles from '@/assets/jss/material-dashboard-react/components/headerLinksStyle.js'

import { logout } from '@/services/auth'

const useStyles = makeStyles(styles)

export default function AdminNavbarLinks() {
  const classes = useStyles()
  const [openProfile, setOpenProfile] = React.useState(null)

  const handleClickProfile = event => {
    event.stopPropagation()
    setOpenProfile(openProfile ? null : event.currentTarget)
  }

  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const handleLogout = () => {
    logout()
    window.location.reload()
  }

  return (
    <div>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>

        <Poppers
          id={openProfile ? 'profile-popper' : undefined}
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={clsx([
            { [classes.popperClose]: !openProfile },
            classes.popperNav,
          ])}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {/* ativar edição de perfil?
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dropdownItem}
                    >
                      Perfil
                    </MenuItem>
                    <Divider light />
                    */}
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  )
}
