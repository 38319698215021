import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

export const UpdatedStep = ({ onClick }) => {
  const classes = useStyles()

  const handleClick = () => {
    onClick()
  }

  return (
    <>
      <div className={classes.icon}>
        <CheckCircleIcon fontSize="large" color="inherit" />
      </div>

      <div className={classes.description}>
        <Typography component="span" variant="h6">
          Sucesso
        </Typography>
        <Typography color="textSecondary">
          Sua nova senha foi salva com sucesso!
        </Typography>
      </div>

      <Button
        size="large"
        color="primary"
        variant="contained"
        className={classes.marginTop}
        onClick={handleClick}
        fullWidth
      >
        Entrar
      </Button>
    </>
  )
}

UpdatedStep.propTypes = {
  onClick: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  icon: {
    textAlign: 'center',
    color: theme.palette.success.main,
  },
  description: {
    'textAlign': 'center',
    '& p': {
      marginTop: theme.spacing(1),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))
