import React from 'react'
import PropTypes from 'prop-types'
import {
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { Dashboard } from '@material-ui/icons'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Accordion from '../Accordion/Accordion'
import LinkIcon from '@material-ui/icons/Link'
import ViewListIcon from '@material-ui/icons/ViewList'
import styles from '@/assets/jss/material-dashboard-react/components/sidebarStyle.js'
import clsx from 'clsx'

const useStyles = makeStyles(styles)

const Links = props => {
  const classes = useStyles()

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false
  }

  function setStyleListItem(layout, path) {
    let listItemClasses

    listItemClasses = clsx({
      [' ' + classes[props.color]]: activeRoute(layout + path),
    })

    return listItemClasses
  }

  function setStyleFontCasses(layout, path) {
    const whiteFontClasses = clsx({
      [' ' + classes.whiteFont]: activeRoute(layout + path),
    })

    return whiteFontClasses
  }

  function renderLink(route) {
    const whiteFontClasses = setStyleFontCasses(route.layout, route.path)
    const listItemClasses = setStyleListItem(route.layout, route.path)

    return (
      <NavLink to={route.layout + route.path} className={' ' + classes.item}>
        <ListItem button className={classes.itemLink + listItemClasses}>
          {typeof route.icon === 'string' ? (
            <Icon
              className={clsx(classes.itemIcon, whiteFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            >
              {route.icon}
            </Icon>
          ) : (
            <route.icon
              className={clsx(classes.itemIcon, whiteFontClasses, {
                [classes.itemIconRTL]: props.rtlActive,
              })}
            />
          )}

          <ListItemText
            primary={props.rtlActive ? route.rtlName : route.name}
            className={clsx(classes.itemText, whiteFontClasses, {
              [classes.itemTextRTL]: props.rtlActive,
            })}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    )
  }

  const recipe2basketRoute = renderLink({
    index: true,
    path: '/recipe2basket',
    name: 'Recipe2Basket',
    icon: Dashboard,
    layout: '/admin',
    roles: [0, 1, 2, 3],
  })

  const userRoute = renderLink({
    path: '/user-management',
    name: 'Usuários',
    icon: AssessmentIcon,
    layout: '/admin',
    roles: [0],
  })

  const accordion = (
    <Accordion
      path="/shoppable-links"
      name="Shoppable Links"
      layout="/admin"
      subRoutes={[
        {
          path: '/shoppable-links/create-shoppable-link',
          name: 'Criar Shoppable Link',
          icon: LinkIcon,
          layout: '/admin',
          roles: [0, 1, 2, 3],
        },
        {
          path: '/shoppable-links/links',
          name: 'Meus links',
          icon: ViewListIcon,
          layout: '/admin',
          roles: [0, 1, 2, 3],
        },
      ]}
    />
  )

  return (
    <List className={classes.list}>
      {recipe2basketRoute}
      {accordion}
      {userRoute}
    </List>
  )
}

Links.propTypes = {
  rtlActive: PropTypes.bool,
  color: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
}

export default Links
