/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { css } from '@emotion/react'
import { MoonLoader } from 'react-spinners'

import { withStyles } from '@material-ui/core/styles'

import AccessTime from '@material-ui/icons/AccessTime'

import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import GridItem from '@/components/Grid/GridItem.js'
import GridContainer from '@/components/Grid/GridContainer.js'
import Card from '@/components/Card/Card.js'
import CardHeader from '@/components/Card/CardHeader.js'
import CardFooter from '@/components/Card/CardFooter.js'
import ChartLine from '@/components/Charts/ChartLine.js'
import ChartFunnel from '@/components/Charts/ChartFunnel.js'
import Button from '@/components/CustomButtons/Button.js'
import CardBody from '@/components/Card/CardBody'
import Table from '@/components/Table/Table'
import TablePagination from '@/components/Table/Pagination_new'
import DatePicker from '@/components/Date/DatePicker'
import { InfoButton } from '@/components/InfoButton'

import { usePartner } from '@/contexts/PartnerContext'

import {
  getEvents,
  getRecipesList,
  recipeEventsParseWithoutPageview,
  supermarketsEventsParse,
  parseRangeToDates,
  totals,
  createMonthDates,
  parseEventsToCsvData,
  getProductsData,
  getProductsTotalValues,
  getTotalProducts,
} from '@/helpers/events'

import styles from '@/assets/jss/material-dashboard-react/views/dashboardStyle.js'
import sendEvent from '@/services/events'
import { currentMonth, monthAbbr } from '@/helpers/dates'
import { downloadFile } from '~/src/helpers/file'
import { jsonToCsv } from '~/src/helpers/csv'
import { Box } from '@material-ui/core'
import dayjs from 'dayjs'
import TableProducts from '~/src/components/Table/TableProducts'

const override = css`
  display: block;
  margin: 0 auto;
`

const tableHeader = [
  'Nome ingrediente',
  'Quantidade',
  'Valor total dos produtos',
  'Marca primária',
  'Marca secundária',
  'Marca terciária',
]

const Dashboard = props => {
  const { classes } = props

  const [events, setEvents] = useState(null)
  const [recipeEvents, setRecipeEvents] = useState(null)
  const [supermarketsEvents, setSupermarketEvents] = useState(null)
  const [products, setProducts] = useState(null)
  const [productsTotalValue, setProductsTotalValue] = useState(null)
  const [productsTotal, setProductsTotal] = useState(null)
  const [initDate, setInitDate] = useState(
    createMonthDates(currentMonth()).initDate
  )
  const [endDate, setEndDate] = useState(
    createMonthDates(currentMonth()).endDate
  )
  const [recipeLimit, setRecipeLimit] = useState(10)
  const [recipeOffset, setRecipeOffset] = useState(0)
  const [supermarketLimit, setSupermarketLimit] = useState(10)
  const [supermarketOffset, setSupermarketOffset] = useState(0)
  const [productsLimit, setProductsLimit] = useState(10)
  const [productsOffset, setProductsOffset] = useState(0)
  const [timeDesc, setTimeDesc] = useState(null)
  const [range, setRange] = useState(monthAbbr(currentMonth()).toLowerCase())
  const [eventsGroupBy, setEventsGroupBy] = useState('date')
  const [firstRequests, setFirstRequests] = useState(true)
  const [customDateModal, setCustomDateModal] = useState(false)
  const [device, setDevice] = useState('todos')
  const [autoComplete, setAutoComplete] = useState([])
  const [openAutoComplete, setOpenAutoComplete] = useState(false)
  const [url, setUrl] = useState(null)
  const [eventsLoading, setEventsLoading] = useState(true)
  const [recipesLoading, setRecipesLoading] = useState(true)
  const [supermarketsLoading, setSupermarketsLoading] = useState(true)
  const [autocompleteLoading, setAutocompleteLoading] = useState(true)
  const [productsLoading, setProductsLoading] = useState(true)

  const [orderByRecipesTable, setOrderByRecipesTable] = useState('Leads')
  const [orderByProductsTable, setOrderByProductsTable] = useState('quantity')
  const [sortRecipesTable, setSortRecipesTable] = useState('desc')
  const [sortProductsTable, setSortProductsTable] = useState('desc')

  const [hasNextPageRecipesTable, setHasNextPageRecipesTable] = useState(false)
  const [hasNextPageMarketsTable, setHasNextPageMarketsTable] = useState(false)
  const [hasNextPageProductsTable, setHasNextPageProductsTable] =
    useState(false)

  const [orderByMarketsTable, setOrderByMarketsTable] = useState('Leads')
  const [sortMarketsTable, setSortMarketsTable] = useState('desc')

  // const [productsTitle, setProductsTitle] = useState()

  const { partner } = usePartner()

  const dateRageProductsColectData = new Date('2024-10-04T00:00:00.000Z')
  const isDateRange = dayjs(initDate).isBefore(dateRageProductsColectData)

  function formatDateToStandard(date) {
    if (!date) return null

    return dayjs(date).format('YYYY-MM-DD HH:mm:ssZ')
  }

  const exportAsCSV = async () => {
    if (!products?.length) return
    const params = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      blog: partner.url,
      device: device,
      orderBy: 'quantity',
      productType: 'recipe2basket',
      sort: sortProductsTable,
      // title: productsTitle,
      url: url,
    }

    if (!params.device) delete params.device
    if (!params.url) delete params.url

    const response = await getProductsData(params)

    if (response) {
      const csvData = jsonToCsv({
        headers: tableHeader,
        content: response.events.reduce((acc, cur) => {
          acc.push([
            cur.ingredientZaply,
            cur.quantity,
            cur.totalValue,
            cur.brand,
            cur.secondaryBrand,
            cur.tertiaryBrand,
          ])
          return acc
        }, []),
      })

      downloadFile({
        data: csvData,
        name: 'zaply-analytics-products.csv',
        type: 'text/csv',
      })
      return
    }
  }

  const resetEvents = () => {
    const noEvents = [
      {
        avg_cart_val: 0,
        button_loaded: 0,
        button_opened: 0,
        buy_click: 0,
        hours: null,
        lead_val: 0,
        leads: 0,
        leads_by_opening: 0,
        leads_by_supermarket: 0,
        product_click: 0,
        supermarket: null,
        supermarket_click: 0,
        url: null,
      },
    ]

    setEvents({
      data: noEvents,
      dataCsv: noEvents,
      totals: totals(noEvents),
    })

    setEventsLoading(false)
  }

  const resetRecipeEvents = () => {
    setRecipeEvents({
      data: [],
    })

    setRecipesLoading(false)
  }

  const resetSupermarketsEvents = () => {
    setSupermarketEvents({
      data: [],
    })

    setSupermarketsLoading(false)
  }

  const fetchEvents = async () => {
    setEventsLoading(true)

    const options = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      productType: 'recipe2basket',
      groupBy: eventsGroupBy,
      orderBy: 'date',
      device,
      blog: partner.url,
    }

    if (!partner.is_app) {
      options.url = url || ''
    } else if (partner.is_app) {
      options.isApp = partner.is_app
      options.title = url || ''
    }

    const { data } = await getEvents(options)

    const eventsData = data.events.shift()
    setEvents({
      data: eventsData,
      totals: totals(eventsData),
      dataCsv: parseEventsToCsvData(eventsData),
    })

    setEventsLoading(false)
  }

  const fetchRecipeEvents = async () => {
    setRecipesLoading(true)

    const options = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      productType: 'recipe2basket',
      groupBy: 'recipe',
      orderBy: orderByRecipesTable,
      sort: sortRecipesTable,
      offset: recipeOffset,
      limit: recipeLimit,
      device,
      isApp: partner.is_app,
      blog: partner.url,
    }

    if (!partner.is_app) {
      options.url = url || ''
    } else if (partner.is_app) {
      options.title = url || ''
    }

    const { data } = await getEvents(options)

    const eventsData = data.events.shift()

    setHasNextPageRecipesTable(data.has_next_page)

    setRecipeEvents({
      data: recipeEventsParseWithoutPageview({
        data: eventsData,
        partner,
      }),
    })

    setRecipesLoading(false)
  }

  const fetchSupermarketsEvents = async () => {
    setSupermarketsLoading(true)

    const options = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      productType: 'recipe2basket',
      groupBy: 'supermarket',
      orderBy: orderByMarketsTable,
      sort: sortMarketsTable,
      offset: supermarketOffset,
      limit: supermarketLimit,
      device,
      blog: partner.url,
    }

    if (!partner.is_app) {
      options.url = url || ''
    } else if (partner.is_app) {
      options.title = url || ''
    }

    const { data } = await getEvents(options)
    const eventsData = data.events.shift()

    setHasNextPageMarketsTable(data.has_next_page)

    setSupermarketEvents({
      data: supermarketsEventsParse({ data: eventsData }),
    })

    setSupermarketsLoading(false)
  }

  const fetchAutoComplete = async () => {
    try {
      setAutocompleteLoading(true)

      const data = await getRecipesList({
        productType: 'recipe2basket',
        partnerUrl: partner.url,
        isApp: partner.is_app,
      })

      setAutoComplete(data)
      setAutocompleteLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchSyncEvents = async () => {
    try {
      await fetchEvents()
    } catch (error) {
      console.error(error)
      resetEvents()
      window.alert('Sem eventos para essa pesquisa.')
    }

    try {
      await fetchRecipeEvents()
    } catch (error) {
      resetRecipeEvents()
    }

    try {
      await fetchSupermarketsEvents()
    } catch (error) {
      resetSupermarketsEvents()
    }
  }

  const fetchProducts = async () => {
    setProductsLoading(true)
    const params = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      blog: partner.url,
      productType: 'recipe2basket',
      device: device,
      orderBy: orderByProductsTable,
      sort: sortProductsTable,
      // title: productsTitle,
      url: url,
      limit: productsLimit,
      offset: productsOffset,
    }

    if (!params.device) delete params.device
    if (!params.url) delete params.url

    const response = await getProductsData(params)

    if (response) {
      setProducts(response.events)
      setHasNextPageProductsTable(response.has_next_page)
      setProductsLoading(false)
      return
    }
    setProductsLoading(false)
    window.alert('Sem produtos para essa pesquisa.')
  }

  const fetchProductsTotalValues = async () => {
    setProductsLoading(true)
    const params = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      blog: partner.url,
      productType: 'recipe2basket',
      device: device,
      url: url,
    }

    if (!params.device) delete params.device
    if (!params.url) delete params.url

    const response = await getProductsTotalValues(params)

    if (response) {
      setProductsTotalValue(response.events[0].totalValue)
      setProductsLoading(false)
      return
    }
    setProductsLoading(false)
    window.alert('Sem produtos para essa pesquisa.')
  }

  const fetchTotalProducts = async () => {
    setProductsLoading(true)
    const params = {
      initDate: formatDateToStandard(initDate),
      endDate: formatDateToStandard(endDate),
      blog: partner.url,
      device: device,
      productType: 'recipe2basket',
      url: url,
    }

    if (!params.device) delete params.device
    if (!params.url) delete params.url

    const response = await getTotalProducts(params)

    if (response) {
      setProductsTotal(response.events[0].totalQuantity)
      setProductsLoading(false)
      return
    }
    setProductsLoading(false)
    window.alert('Sem produtos para essa pesquisa.')
  }

  useEffect(() => {
    fetchSyncEvents()
    fetchAutoComplete()

    setFirstRequests(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!firstRequests) {
      fetchSyncEvents()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeDesc, device, url])

  useEffect(() => {
    if (!firstRequests) {
      try {
        fetchRecipeEvents()
      } catch (error) {
        console.error(error)
        resetRecipeEvents()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeLimit, recipeOffset, orderByRecipesTable, sortRecipesTable])

  useEffect(() => {
    fetchProductsTotalValues()
    fetchTotalProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initDate, endDate, device, url, partner, device])

  useEffect(() => {
    fetchProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    device,
    url,
    initDate,
    endDate,
    productsLimit,
    productsOffset,
    orderByProductsTable,
    sortProductsTable,
    partner,
  ])

  useEffect(() => {
    if (!firstRequests) {
      try {
        fetchSupermarketsEvents()
      } catch (error) {
        console.error(error)
        resetSupermarketsEvents()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    supermarketLimit,
    supermarketOffset,
    orderByMarketsTable,
    sortMarketsTable,
  ])

  useEffect(() => {
    if (range && range !== 'custom' && !firstRequests) {
      const rangeDates = parseRangeToDates(range, {
        initDate: formatDateToStandard(initDate),
        endDate: formatDateToStandard(endDate),
      })

      setInitDate(rangeDates.initDate)
      setEndDate(rangeDates.endDate)
      setTimeDesc(rangeDates.timeDesc)
      setEventsGroupBy(rangeDates.eventGroup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range])

  return (
    <div>
      <GridContainer direction="row" xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={2}>
          <FormControl style={{ minWidth: '170px' }}>
            <InputLabel shrink>Filtrar por data</InputLabel>
            <Select
              value={range}
              onClick={event => {
                const { value } = event.target

                if (value === 'custom' || (value === 0 && range === 'custom')) {
                  setCustomDateModal(true)
                  setRange('custom')
                  return
                }

                if (!value) return

                setEventsGroupBy(
                  value === 'today' || value === 'yesterday' ? 'hour' : 'date'
                )

                setRange(String(value).toLowerCase())
              }}
              disabled={eventsLoading}
            >
              <ListSubheader>Geral</ListSubheader>
              <MenuItem value="today">Hoje</MenuItem>
              <MenuItem value="yesterday">Ontem</MenuItem>
              <MenuItem value="custom">Personalizada</MenuItem>

              <ListSubheader>Mensal</ListSubheader>
              <MenuItem value="jan">Janeiro</MenuItem>
              <MenuItem value="feb">Fevereiro</MenuItem>
              <MenuItem value="mar">Março</MenuItem>
              <MenuItem value="apr">Abril</MenuItem>
              <MenuItem value="may">Maio</MenuItem>
              <MenuItem value="jun">Junho</MenuItem>
              <MenuItem value="jul">Julho</MenuItem>
              <MenuItem value="aug">Agosto</MenuItem>
              <MenuItem value="sep">Setembro</MenuItem>
              <MenuItem value="oct">Outubro</MenuItem>
              <MenuItem value="nov">Novembro</MenuItem>
              <MenuItem value="dec">Dezembro</MenuItem>
            </Select>

            <DatePicker
              value={initDate}
              open={customDateModal}
              onSend={value => {
                const rangeDates = parseRangeToDates(range, {
                  initDate: value.selectedInitDate,
                  endDate: value.selectedEndDate,
                })

                setInitDate(rangeDates.initDate)
                setEndDate(rangeDates.endDate)
                setTimeDesc(rangeDates.timeDesc)
                setEventsGroupBy(rangeDates.eventGroup)
                setCustomDateModal(false)
              }}
              onClose={() => setCustomDateModal(false)}
            />
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={12} md={2}>
          <FormControl style={{ minWidth: '170px' }}>
            <InputLabel shrink>Dispositivo</InputLabel>
            <Select
              value={device}
              onChange={event => {
                if (!event.target.value) return
                setDevice(event.target.value)
              }}
              disabled={eventsLoading}
            >
              <MenuItem value="mobile">Mobile</MenuItem>
              <MenuItem value="desktop">Desktop</MenuItem>
              <MenuItem value="tablet">Tablet</MenuItem>
              <MenuItem value="todos">Todos</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>

      <GridContainer style={{ marginTop: 15 }} xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={8}>
          <Autocomplete
            id="asynchronous"
            open={openAutoComplete}
            onOpen={() => setOpenAutoComplete(true)}
            onClose={() => setOpenAutoComplete(false)}
            onChange={event => setUrl(event.target.innerText)}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={option => option}
            options={autoComplete}
            loading={autocompleteLoading}
            disabled={autocompleteLoading}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Busque por receita"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InfoButton
                      title="Busque por receita"
                      description="Filtre os dados de interações pela(s) receita(s) desejada(s)"
                    />
                  ),
                  endAdornment: (
                    <React.Fragment>
                      {autocompleteLoading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          {!eventsLoading && events && (
            <Button
              disabled={!events.totals}
              style={{ height: '100%', width: '100%', margin: 0 }}
              variant="contained"
              color="success"
            >
              <CSVLink
                style={{ color: 'white' }}
                filename={`recipe2basket-events-${new Date().getFullYear()}${new Date().getMonth()}${new Date().getDate()}.csv`}
                data={events.dataCsv}
                onClick={() =>
                  sendEvent({
                    type: 'exportdata',
                    blog: partner.id,
                  })
                }
              >
                Exportar dados para um CSV
              </CSVLink>
            </Button>
          )}
        </GridItem>
      </GridContainer>

      <GridContainer xs={12} sm={12} md={12}>
        <GridContainer xs={12} sm={12} md={6} style={{ height: 'fit-content' }}>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>Interações de compras</p>
                <h3 className={classes.cardTitle}>
                  {!eventsLoading && events ? (
                    events.totals.button_opened.toLocaleString('pt-br', {
                      style: 'decimal',
                    })
                  ) : (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={eventsLoading}
                    />
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Interações de compras"
                  description='Número de cliques no botão "comprar ingredientes".'
                />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>Carrinhos de compra</p>
                <h3 className={classes.cardTitle}>
                  {!eventsLoading && events ? (
                    (events.totals.buy_click || 0).toLocaleString('pt-br', {
                      style: 'decimal',
                    })
                  ) : (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={eventsLoading}
                    />
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Carrinhos de compra"
                  description="Número de carrinhos enviados aos varejistas."
                />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>
                  Conversão de interações em carrinhos
                </p>
                <h3 className={classes.cardTitle}>
                  {!eventsLoading && events ? (
                    (
                      (events.totals.leads / events.totals.button_opened) *
                        100 || 0
                    ).toFixed(1) + ' %'
                  ) : (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={eventsLoading}
                    />
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Conversão de interações em carrinhos"
                  description='Taxa de conversão de usuários que interagiram com o botão de "comprar ingredientes" redirecionados para o varejista.'
                />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>Valor dos carrinhos</p>
                <h3 className={classes.cardTitle}>
                  {!eventsLoading && events ? (
                    (events.totals.lead_val || 0).toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'brl',
                    })
                  ) : (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={eventsLoading}
                    />
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Valor dos carrinhos"
                  description="Preço total dos carrinhos enviados aos varejistas."
                />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>
                  Quantidade de produtos de marca
                </p>
                <h3 className={classes.cardTitle}>
                  {productsLoading ? (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={productsLoading}
                    />
                  ) : productsTotal ? (
                    productsTotal.toLocaleString('pt-br', {
                      style: 'decimal',
                    })
                  ) : (
                    0
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Quantidade de produtos de marca"
                  description="Relaciona o somatório em quantidade de todos produtos de marca adicionados ao carrinho"
                />
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <Card>
              <CardHeader color="success" stats icon>
                <p className={classes.cardCategory}>
                  Valor dos produtos de marca
                </p>
                <h3 className={classes.cardTitle}>
                  {productsLoading ? (
                    <MoonLoader
                      css={override}
                      size={45}
                      color={'#123abc'}
                      loading={productsLoading}
                    />
                  ) : productsTotalValue ? (
                    productsTotalValue.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'brl',
                    })
                  ) : (
                    0
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>{timeDesc}</div>
                <InfoButton
                  title="Valor dos produtos de marca"
                  description="Relaciona o somatório em valor de todos produtos de marca adicionados ao carrinho."
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle}>Funil de conversão</h4>

                {!eventsLoading && !!events?.totals ? (
                  <ChartFunnel
                    data={events.totals}
                    style={{ width: '100%', height: 475 }}
                  />
                ) : (
                  <MoonLoader
                    css={override}
                    size={45}
                    color={'#123abc'}
                    loading={eventsLoading}
                  />
                )}
              </CardHeader>

              {!!timeDesc && (
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime /> {timeDesc}
                  </div>
                </CardFooter>
              )}
            </Card>
          </GridContainer>
        </GridItem>
      </GridContainer>

      {isDateRange && (
        <p
          style={{
            marginTop: '-15px',
            marginLeft: '10px',
          }}
        >
          - Dados disponíveis a partir de 04/10/2024.
        </p>
      )}

      <GridContainer xs={12} sm={12} md={12}>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle}>Interações de compras</h4>

                {!eventsLoading &&
                !!events?.totals &&
                !!events?.data?.length ? (
                  events.totals.button_opened === 0 ? (
                    <p style={{ textAlign: 'center' }}>
                      Sem dados para o período selecionado
                    </p>
                  ) : (
                    <ChartLine
                      eventGroup={eventsGroupBy}
                      data={events.data}
                      field="button_opened"
                      fieldName="Cliques"
                      style={{
                        width: '100%',
                        height: 195,
                      }}
                    />
                  )
                ) : (
                  <MoonLoader
                    css={override}
                    size={45}
                    color="#123abc"
                    loading={eventsLoading}
                  />
                )}
              </CardHeader>

              <CardFooter chart>
                {!!timeDesc && (
                  <div className={classes.stats}>
                    <AccessTime /> {timeDesc}
                  </div>
                )}
                <InfoButton
                  title="Interações de compras"
                  description='Número de cliques no botão "comprar ingredientes".'
                />
              </CardFooter>
            </Card>
          </GridContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <GridContainer xs={12} sm={12} md={12}>
            <Card chart>
              <CardHeader>
                <h4 className={classes.cardTitle}>Carrinhos de compra</h4>

                {!eventsLoading &&
                !!events?.totals &&
                !!events?.data?.length ? (
                  events.totals.leads === 0 ? (
                    <p style={{ textAlign: 'center' }}>
                      Sem dados para o período selecionado
                    </p>
                  ) : (
                    <ChartLine
                      eventGroup={eventsGroupBy}
                      data={events.data}
                      field="leads"
                      fieldName="Carrinhos"
                      style={{
                        width: '100%',
                        height: 195,
                      }}
                    />
                  )
                ) : (
                  <MoonLoader
                    css={override}
                    size={45}
                    color={'#123abc'}
                    loading={eventsLoading}
                  />
                )}
              </CardHeader>
              <CardFooter chart>
                {!!timeDesc && (
                  <div className={classes.stats}>
                    <AccessTime /> {timeDesc}
                  </div>
                )}
                <InfoButton
                  title="Carrinhos de compra"
                  description="Número de carrinhos enviados aos varejistas."
                />
              </CardFooter>
            </Card>
          </GridContainer>
        </GridItem>

        <GridItem style={{ margin: '50px 0 20px' }} xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>Links mais engajados</h4>
              <p className={classes.cardCategoryWhite}>{timeDesc}</p>
              <InfoButton
                title="Links mais engajados"
                description="Receitas que tiveram mais interações com o botão de compras no período selecionado."
              />
            </CardHeader>
            <CardBody>
              {!recipesLoading ? (
                !recipeEvents?.data?.length ? (
                  <p style={{ textAlign: 'center' }}>
                    Sem dados para o período selecionado
                  </p>
                ) : (
                  <Table
                    tableHead={[
                      'URL',
                      'Interações de compras',
                      'Varejista selecionado',
                      'Carrinhos de compra',
                      'Valor dos carrinhos',
                    ]}
                    tableSort={[
                      {
                        key: 'URL',
                        value: partner.is_app ? 'title' : 'url',
                      },
                      { key: 'Interações de compras', value: 'Iterações' },
                      {
                        key: 'Varejista selecionado',
                        value: 'Cliques "Ver lista"',
                      },
                      { key: 'Carrinhos de compra', value: 'Leads' },
                      { key: 'Valor dos carrinhos', value: 'Valor dos leads' },
                    ]}
                    tableData={recipeEvents.data}
                    tableHeaderColor="success"
                    orderBy={orderByRecipesTable}
                    sort={sortRecipesTable}
                    onClick={({ orderBy, sort }) => {
                      setOrderByRecipesTable(orderBy)
                      setSortRecipesTable(sort)
                    }}
                  />
                )
              ) : (
                <MoonLoader
                  css={override}
                  size={45}
                  color={'#123abc'}
                  loading={recipesLoading}
                />
              )}
            </CardBody>

            {!!recipeEvents?.data?.length && (
              <TablePagination
                options={{
                  limit: recipeLimit,
                  setLimit: setRecipeLimit,
                  offset: recipeOffset,
                  setOffset: setRecipeOffset,
                  hasNextPage: hasNextPageRecipesTable,
                }}
              />
            )}
          </Card>
        </GridItem>

        <GridItem style={{ margin: '50px 0 20px' }} xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>
                Varejistas mais acessados
              </h4>
              <p className={classes.cardCategoryWhite}>{timeDesc}</p>
              <InfoButton
                title="Varejistas mais acessados"
                description="Varejistas mais clicados no período selecionado."
              />
            </CardHeader>
            <CardBody>
              {!supermarketsLoading ? (
                !supermarketsEvents?.data?.length ? (
                  <p style={{ textAlign: 'center' }}>
                    Sem dados para o período selecionado
                  </p>
                ) : (
                  <Table
                    tableHead={[
                      'Supermercado',
                      'Varejista selecionado',
                      'Carrinhos de compra',
                      'Carrinhos de compra/Varejista selecionado',
                      'Valor dos carrinhos',
                    ]}
                    tableSort={[
                      { key: 'Supermercado', value: 'supermarket' },
                      {
                        key: 'Varejista selecionado',
                        value: 'Cliques "Ver lista"',
                      },
                      { key: 'Carrinhos de compra', value: 'Leads' },
                      {
                        key: 'Carrinhos de compra/Varejista selecionado',
                        value: 'leads_by_supermarket',
                      },
                      { key: 'Valor dos carrinhos', value: 'Valor dos leads' },
                    ]}
                    tableData={supermarketsEvents.data}
                    tableHeaderColor="success"
                    orderBy={orderByMarketsTable}
                    sort={sortMarketsTable}
                    onClick={({ orderBy, sort }) => {
                      setOrderByMarketsTable(orderBy)
                      setSortMarketsTable(sort)
                    }}
                  />
                )
              ) : (
                <MoonLoader
                  css={override}
                  size={45}
                  color={'#123abc'}
                  loading={supermarketsLoading}
                />
              )}
            </CardBody>

            {!!supermarketsEvents?.data?.length && (
              <TablePagination
                options={{
                  limit: supermarketLimit,
                  setLimit: setSupermarketLimit,
                  offset: supermarketOffset,
                  setOffset: setSupermarketOffset,
                  hasNextPage: hasNextPageMarketsTable,
                }}
              />
            )}
          </Card>
        </GridItem>
        {products?.length ? (
          <Box
            style={{
              width: '100%',
              marginRight: '25px',
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Button
              style={{
                height: '100%',
                width: '100%',
                maxWidth: '350px',
              }}
              variant="contained"
              color="success"
              onClick={exportAsCSV}
            >
              Exportar dados para um CSV
            </Button>
          </Box>
        ) : null}
        <GridItem style={{ margin: '20px 0 20px' }} xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4 className={classes.cardTitleWhite}>
                Dados de produtos de marca
              </h4>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <p className={classes.cardCategoryWhite}>{timeDesc} </p>
                {isDateRange && (
                  <p
                    className={classes.cardCategoryWhite}
                    style={{ marginLeft: '5px' }}
                  >
                    - Dados disponíveis a partir de 04/10/2024.
                  </p>
                )}
              </div>
              <InfoButton
                title="Dados de produtos de marca"
                description="Lista de produtos de marca mais adicionados ao carrinho somando todos varejos ativos. Aqui podem haver produtos de marcas parceiras que tenham ingredientes definidos como produto de marca em seu inventário de conteúdo."
              />
            </CardHeader>
            <CardBody>
              {!productsLoading ? (
                !products?.length ? (
                  <p style={{ textAlign: 'center' }}>
                    Sem dados para o período selecionado
                  </p>
                ) : (
                  <TableProducts
                    tableHead={[
                      'Produto',
                      'Quantidade',
                      'Valor total dos produtos',
                      'Marca primária',
                      'Marca secundária',
                      'Marca terciária',
                    ]}
                    tableSort={[
                      {
                        key: 'Produto',
                        value: 'ingredientZaply',
                      },
                      {
                        key: 'Quantidade',
                        value: 'quantity',
                      },
                      { key: 'Valor total dos produtos', value: 'totalValue' },
                      {
                        key: 'Marca',
                        value: 'brand',
                      },
                      { key: 'Marca secundária', value: 'secondaryBrand' },
                      { key: 'Marca terciária', value: 'tertiaryBrand' },
                    ]}
                    tableData={products}
                    tableHeaderColor="success"
                    orderBy={orderByProductsTable}
                    sort={sortProductsTable}
                    onClick={({ orderBy, sort }) => {
                      setOrderByProductsTable(orderBy)
                      setSortProductsTable(sort)
                    }}
                  />
                )
              ) : (
                <MoonLoader
                  css={override}
                  size={45}
                  color={'#123abc'}
                  loading={productsLoading}
                />
              )}
              {!!products?.length && (
                <TablePagination
                  options={{
                    limit: productsLimit,
                    setLimit: setProductsLimit,
                    offset: productsOffset,
                    setOffset: setProductsOffset,
                    hasNextPage: hasNextPageProductsTable,
                  }}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

export default withStyles(styles)(Dashboard)
