import PropTypes from 'prop-types'

import { makeStyles, withStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import styles from '@/assets/jss/material-dashboard-react/components/tableStyle.js'
import { currencyFormatter } from '~/src/helpers/formarters'

const TableSortLabelAlternative = withStyles({
  active: {
    color: 'rgb(76, 175, 80)!important',
  },
})(TableSortLabel)

const useStyles = makeStyles(styles)

export default function TableProducts({
  tableHead,
  tableData,
  tableSort,
  tableHeaderColor,
  orderBy,
  sort,
  onClick,
  disableSort,
}) {
  const classes = useStyles()

  function formatCompactedCurrency(value) {
    if (value === null || value === undefined) {
      return 'R$ 0,00'
    }
    return currencyFormatter('pt-BR', {
      notation: 'standard',
      currency: 'BRL',
    })(value)
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {!!tableHead && (
          <TableHead className={classes[tableHeaderColor + 'TableHeader']}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map(prop => (
                <TableCell
                  key={prop}
                  className={classes.tableCell + ' ' + classes.tableHeadCell}
                >
                  {disableSort ? (
                    prop
                  ) : (
                    <TableSortLabelAlternative
                      active={prop === orderBy}
                      direction={sort}
                      onClick={() =>
                        onClick({
                          orderBy:
                            tableSort.find(i => i.key === prop)?.value || prop,
                          sort: sort === 'desc' ? 'asc' : 'desc',
                        })
                      }
                    >
                      {prop}
                    </TableSortLabelAlternative>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {tableData.map((prop, index) => (
            <TableRow key={index} hover className={classes.tableBodyRow}>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '20rem' }}
              >
                {prop.ingredientZaply ? prop.ingredientZaply : '-'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '100%' }}
              >
                {prop.quantity ? prop.quantity : '-'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '100%' }}
              >
                {prop.totalValue
                  ? formatCompactedCurrency(prop.totalValue)
                  : '-'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '100%' }}
              >
                {prop.brand ? prop.brand : '-'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '100%' }}
              >
                {prop.secondaryBrand ? prop.secondaryBrand : '-'}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                style={{ maxWidth: '100%' }}
              >
                {prop.tertiaryBrand ? prop.tertiaryBrand : '-'}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

TableProducts.defaultProps = {
  tableHeaderColor: 'gray',
  disableSort: false,
}

TableProducts.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(
    PropTypes.objectOf({
      ingredientZaply: PropTypes.string.isRequired,
      totalValue: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      brand: PropTypes.number.isRequired,
      secondaryBrand: PropTypes.number.isRequired,
      tertiaryBrand: PropTypes.number.isRequired,
    })
  ).isRequired,
  tableSort: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  tableHeaderColor: PropTypes.oneOf([
    'warning',
    'primary',
    'danger',
    'success',
    'info',
    'rose',
    'gray',
  ]),
  orderBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disableSort: PropTypes.bool,
}
