export function downloadFile(options) {
  const { data, name, type } = options
  const blob = new Blob([data], { type })
  const url = URL.createObjectURL(blob)

  const link = document.createElement('a')
  link.download = name
  link.href = url

  const event = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  link.dispatchEvent(event)
  link.remove()
}
