import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AccordionContainer, AccordionItem, Container } from './style'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import Dashboard from '@material-ui/icons/Dashboard'
import { AccordionProps } from './types'

const Accordion: React.FC<AccordionProps> = props => {
  const { path, name, layout, subRoutes } = props

  const navigation = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const [open, setOpen] = React.useState(false)

  function activeRoute(routeName: string) {
    return pathname === routeName ? true : false
  }

  function handleRouter(route: string) {
    navigation(route)
  }

  function handleOpenAccordion() {
    setOpen(!open)
    handleRouter(layout + path)
  }

  useEffect(() => {
    if (!pathname.includes(layout + path)) {
      setOpen(false)
    } else {
      setOpen(true)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Container>
      <AccordionContainer>
        <AccordionItem
          onClick={handleOpenAccordion}
          active={activeRoute(layout + path)}
          index={0}
        >
          <div>
            <Dashboard />
            <p>{name}</p>
          </div>
          {open ? (
            <KeyboardArrowUpIcon className="icon-arrow" />
          ) : (
            <KeyboardArrowDownIcon className="icon-arrow" />
          )}
        </AccordionItem>
        {open &&
          subRoutes?.map((subRoute, index) => (
            <AccordionItem
              key={index}
              index={index + 1}
              onClick={() => handleRouter(subRoute.layout + subRoute.path)}
              active={activeRoute(subRoute.layout + subRoute.path)}
            >
              <div>
                {<subRoute.icon />}
                <p>{subRoute.name}</p>
              </div>
            </AccordionItem>
          ))}
      </AccordionContainer>
    </Container>
  )
}

export default Accordion
