import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { mainApi as api } from '@/services/api'

import { CircularProgress } from '@material-ui/core'

import { Form, Container } from './styles'

export default function AuthRequest() {
  const urlQuery = new URLSearchParams(window.location.search)
  const hash = urlQuery.get('sh')
  const res = urlQuery.get('res')

  const [message, setMessage] = useState('')
  const [state, setState] = useState('loading')

  function sendAuthRequestResponseMessage(hash, message) {
    return api
      .post(`/blog-users/check/${encodeURIComponent(hash)}`, {
        message,
      })
      .catch(() => setState('error'))
  }

  async function handleSubmit(e) {
    e.preventDefault()
    setState('loading')
    await sendAuthRequestResponseMessage(hash, message)
    setState('sended')
  }

  useEffect(() => {
    api
      .get(`/blog-users/check/${encodeURIComponent(hash)}?res=${res}`)
      .then(({ data }) => setState(data))
      .catch(() => setState('error'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash])

  return (
    <Container>
      <Form>
        <div className="header">
          <h3 className="title">Recipe2basket</h3>
        </div>
        {state === 'loading' ? (
          <>
            <h4 className="accepted">Aguarde</h4>
            <div style={{ flexGrow: 1 }} />
            <CircularProgress className="loader" />
          </>
        ) : state === 'ACCEPTED' ? (
          <>
            <h4 className="accepted">Pedido Aceito</h4>
            <span>
              Agora o novo usuário vai receber um email para terminar seu
              cadastro e logo receberá acesso ao Recipe2Basket Analytics
            </span>
            <div style={{ flexGrow: 1 }} />
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : state === 'DENIED' ? (
          <>
            <h4 className="denied">Pedido Negado</h4>
            <span>
              Por favor, nos diga porque você não aceitou o usuário, essa
              informação será muito útil para nossa equipe poder lhe ajudar.
            </span>
            <textarea
              maxLength="240"
              onChange={e => setMessage(e.target.value)}
            ></textarea>
            <div style={{ flexGrow: 1 }} />
            <button onClick={handleSubmit}>Enviar</button>
          </>
        ) : state === 'EXPIRED_ACCEPTED' ? (
          <>
            <h4 className="accepted">Pedido Aceito</h4>
            <span>
              O acesso deste usuário ja foi liberado por outro gestor.
            </span>
            <div style={{ flexGrow: 1 }} />
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : state === 'EXPIRED_DENIED' ? (
          <>
            <h4 className="denied">Pedido Negado</h4>
            <span>O acesso deste usuário ja foi negado por outro gestor.</span>
            <div style={{ flexGrow: 1 }} />
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : state === 'sended' ? (
          <>
            <h4 className="accepted">Mensagem Enviada</h4>
            <span>
              Muito obrigado pelo seu feedback,
              <br />
              ele é muito importante para nós do Recipe2basket.
            </span>
            <div style={{ flexGrow: 1 }} />
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : (
          <>
            <h4 className="denied">Não Encontrado</h4>
            <span>Esta requisição não foi encontrada.</span>
            <div style={{ flexGrow: 1 }} />
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        )}
        <div style={{ flexGrow: 1 }} />
      </Form>
    </Container>
  )
}
