import React, { useEffect } from 'react'
import { DialogProps, Popper } from '@material-ui/core'
import {
  Button,
  Link,
  ModalContent,
  ModalFooter,
  ModalHeader,
  IconClipboard,
  LinkContainer,
  PoppoverContent,
  Modal,
  IconSuccess,
  IconError,
} from './style'
import { useNavigate } from 'react-router-dom'
import { usePowerLinks } from '~/src/services/powerlinks'
import { usePartner } from '~/src/contexts/PartnerContext'

interface ModalProps extends DialogProps {
  title: string
  link: string
  open: boolean
  onClose: () => void
  modalStatus?: 'success' | 'error' | null
}

const ModalResponse: React.FC<ModalProps> = props => {
  const { title, link, open, onClose, modalStatus, ...rest } = props
  const { partner } = usePartner()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [openPopover, setOpenPopover] = React.useState(false)
  const navigate = useNavigate()
  const id = open ? 'simple-popper' : undefined
  const url = `https://slink.zaply.com.br/${link}`

  const { data: shoppableLink } = usePowerLinks({
    blogId: partner?.id,
    limit: 10,
    order: 'url',
    page: 0,
    sort: 'asc',
    searchTerm: title,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  function copyToClipboard(event: React.MouseEvent<HTMLElement>) {
    if (modalStatus === 'success') {
      navigator.clipboard.writeText(url)
    } else {
      navigator.clipboard.writeText(
        `https://slink.zaply.com.br/${shoppableLink?.shoppableLinks?.[0].slug}`
      )
    }
    setOpenPopover(true)
    handleClick(event)
  }

  function navigateToLinks() {
    navigate('/admin/shoppable-links/links')
  }

  useEffect(() => {
    if (openPopover) {
      setTimeout(() => {
        setOpenPopover(false)
      }, 3000)
    }
  }, [openPopover])

  return (
    <Modal open={open} {...rest}>
      <ModalHeader>
        {modalStatus === 'success' ? 'Sucesso' : 'Aviso'}
      </ModalHeader>
      <ModalContent>
        {modalStatus === 'success' ? <IconSuccess /> : <IconError />}
        {modalStatus === 'success' ? (
          <p>
            Shoppable link <span>{title}</span> criado com sucesso
          </p>
        ) : (
          <>
            <p>Já existe um Shoppable Link vinculado a este conteúdo</p>
            {shoppableLink && (
              <>
                <LinkContainer>
                  <Link
                    to={`https://slink.zaply.com.br/${shoppableLink?.shoppableLinks?.[0]?.slug}`}
                    target="_blank"
                  >
                    https://slink.zaply.com.br/
                    {shoppableLink?.shoppableLinks?.[0]?.slug}
                  </Link>
                  <button
                    aria-describedby={id}
                    type="button"
                    onClick={copyToClipboard}
                  >
                    <IconClipboard color="action" />
                  </button>
                </LinkContainer>
                <Popper
                  id={id}
                  disablePortal={true}
                  open={openPopover}
                  anchorEl={anchorEl}
                  placement="top-end"
                >
                  <PoppoverContent>Link copiado.</PoppoverContent>
                </Popper>
              </>
            )}
          </>
        )}
        {modalStatus === 'success' && (
          <>
            <LinkContainer>
              <Link to={url} target="_blank">
                {url}
              </Link>
              <button
                aria-describedby={id}
                type="button"
                onClick={copyToClipboard}
              >
                <IconClipboard color="action" />
              </button>
            </LinkContainer>
            <Popper
              id={id}
              disablePortal={true}
              open={openPopover}
              anchorEl={anchorEl}
              placement="top-end"
            >
              <PoppoverContent>Link copiado.</PoppoverContent>
            </Popper>
          </>
        )}
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose}>Novo Shoppable Link</Button>
        <Button onClick={navigateToLinks}>Ver todos</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalResponse
