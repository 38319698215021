import { useEffect, useState, useRef } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { AxiosError } from 'axios'

import { mainApi as api } from '@/services/api'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import EmailRoundedIcon from '@material-ui/icons/EmailRounded'

export const ResetStep = ({ onChangeEmail, onSubmitForm }) => {
  const classes = useStyles()

  const emailRef = useRef(null)
  const emailConfirmRef = useRef(null)

  const [alert, setAlert] = useState({
    status: false,
    title: '',
    text: '',
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      emailConfirm: '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = { email: values.email }

      api
        .post('/blog-users/password', payload)
        .then(() => {
          setSubmitting(false)
          onSubmitForm()
        })
        .catch(handleSubmitErrors)
    },
  })

  const handleSubmitErrors = error => {
    if (!(error instanceof AxiosError)) return

    const errors = {
      400: handleBadRequestError,
      unknown: handleUnknownError,
    }

    if (error.response?.status in errors) {
      errors[error.response.status]()
      return
    }

    errors['unknown']()
  }

  const handleBadRequestError = () => {
    setAlert({
      status: true,
      title: 'E-mail inválido',
      text: 'Não conseguimos encontrar uma conta associada ao e-mail fornecido.',
    })
  }

  const handleUnknownError = () => {
    setAlert({
      status: true,
      title: 'Erro inesperado',
      text: 'Falha ao enviar as instruções, tente novamente.',
    })
  }

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [])

  return (
    <>
      <div className={classes.description}>
        <Typography component="span" variant="h6">
          Redefinir senha
        </Typography>
        <Typography color="textSecondary">
          Digite o e-mail associado a sua conta e nós enviaremos um e-mail com
          instruções para redefinir sua senha.
        </Typography>
      </div>

      <form
        noValidate
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={classes.marginTop}
      >
        <FormControl
          fullWidth
          margin="none"
          error={!!formik.touched.email && !!formik.errors.email}
        >
          <InputLabel variant="outlined">E-mail</InputLabel>
          <OutlinedInput
            {...formik.getFieldProps('email')}
            inputRef={emailRef}
            type="email"
            label="E-mail"
            variant="outlined"
            onChange={event => {
              onChangeEmail(event.target.value)
              formik.getFieldProps('email').onChange(event)
            }}
            endAdornment={
              <InputAdornment position="start">
                <Icon aria-hidden color="primary">
                  <EmailRoundedIcon />
                </Icon>
              </InputAdornment>
            }
          />
          {!!formik.touched.email && !!formik.errors.email && (
            <FormHelperText error>{formik.errors.email}</FormHelperText>
          )}
        </FormControl>

        <FormControl
          fullWidth
          margin="none"
          className={classes.marginTop}
          error={!!formik.touched.emailConfirm && !!formik.errors.emailConfirm}
        >
          <InputLabel variant="outlined">Confirme o e-mail</InputLabel>
          <OutlinedInput
            {...formik.getFieldProps('emailConfirm')}
            inputRef={emailConfirmRef}
            type="email"
            label="Confirme o e-mail"
            variant="outlined"
            endAdornment={
              <InputAdornment position="start">
                <Icon aria-hidden color="primary">
                  <EmailRoundedIcon />
                </Icon>
              </InputAdornment>
            }
          />
          {!!formik.touched.emailConfirm && !!formik.errors.emailConfirm && (
            <FormHelperText error>{formik.errors.emailConfirm}</FormHelperText>
          )}
        </FormControl>

        <Button
          fullWidth
          type="submit"
          size="large"
          color="primary"
          variant="contained"
          className={classes.marginTop}
          disabled={!formik.isValid || formik.isSubmitting}
          startIcon={
            formik.isSubmitting && (
              <CircularProgress size="1em" color="inherit" />
            )
          }
        >
          Enviar instruções
        </Button>
      </form>

      {!!alert.status && (
        <Alert
          severity="error"
          closeText="Fechar"
          onClose={() =>
            setAlert({
              status: false,
              alert: '',
              text: '',
            })
          }
          className={classes.marginTop}
        >
          {!!alert.title && <AlertTitle>{alert.title}</AlertTitle>}
          {alert.text}
        </Alert>
      )}
    </>
  )
}

ResetStep.propTypes = {
  onChangeEmail: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Digite um endereço de e-mail válido')
    .required('O e-mail é obrigatório'),
  emailConfirm: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Ambos e-mails devem ser iguais')
    .required('A confirmação do e-mail é obrigatória'),
})

const useStyles = makeStyles(theme => ({
  description: {
    'textAlign': 'center',
    '& p': {
      marginTop: theme.spacing(1),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))
