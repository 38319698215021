/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

import { PartnerProvider, usePartner } from '@/contexts/PartnerContext'

import Navbar from '@/components/Navbars/Navbar'
import Footer from '@/components/Footer/Footer'
import Sidebar from '@/components/Sidebar/Sidebar'

import styles from '@/assets/jss/material-dashboard-react/layouts/adminStyle'

import bgImage from '@/assets/img/sidebar-2.jpg'

import { getUserData } from '@/services/auth'

import routesDashboard from './RoutesDashboard'

import 'perfect-scrollbar/css/perfect-scrollbar.css'

const useStyles = makeStyles(styles)

export function AdminWithProvider({ children, ...rest }) {
  const classes = useStyles()

  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()

  const [image] = React.useState(bgImage)
  const [color] = React.useState('blue')
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const { appLoading } = usePartner()
  const [userInfo, setUserInfo] = useState(null)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const getRoute = () => {
    return window.location.pathname !== '/admin/maps'
  }

  useEffect(() => {
    ;(async function () {
      const { role } = await getUserData()

      setUserInfo({
        role,
        routes: routesDashboard.filter(prop =>
          `${role}` === '0' ? true : prop.roles.includes(role)
        ),
      })
    })()
  }, [])

  if (!userInfo) {
    return null
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={userInfo.routes}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        {...rest}
      />

      <div className={classes.mainPanel}>
        <Navbar
          routes={userInfo.routes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />

        {getRoute() ? (
          <>
            <div className={classes.content} ref={mainPanel}>
              {appLoading ? (
                <LinearProgress style={{ width: '100%' }} />
              ) : (
                <div className={classes.container}>{children}</div>
              )}
            </div>
            <Footer />
          </>
        ) : (
          <div className={classes.map}>{children}</div>
        )}
      </div>
    </div>
  )
}

const Admin = props => (
  <PartnerProvider>
    <AdminWithProvider {...props} />
  </PartnerProvider>
)

export default Admin
