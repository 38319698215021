import styled from 'styled-components'

export const TitleBar = styled.h4`
  margin: 5px 0;
`

export const Title = styled.h4`
  text-align: center;
  margin: 5px 0;
`

export const Body = styled.div`
  padding: 0 10px 20px;
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* align-items: center; */
  color: black;
`

export const Loader = styled.div`
  text-align: center;
  margin: auto;
`

export const Error = styled.p`
  color: red;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`

export const DescField = styled.div`
  width: fit-content;
  margin: 5 10px;

  b {
    font-size: 0.9rem;
  }
`

export const BasicInfos = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`
