import { useId, useState } from 'react'

import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import InfoIcon from '@material-ui/icons/InfoRounded'

import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'

export const InfoButton = ({ title, description }) => {
  const classes = useStyles()

  const randomId = useId()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = Boolean(anchorEl)
  const id = isOpen ? `popover-${randomId}` : undefined

  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        aria-owns={isOpen ? id : undefined}
        aria-haspopup="true"
        aria-label="Informações"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        className={classes.button}
      >
        <InfoIcon />
      </IconButton>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
        className={classes.popover}
        classes={{ paper: classes.paper }}
        disableRestoreFocus
      >
        {!!title && <span className="title">{title}</span>}
        <p className="description">{description}</p>
      </Popover>
    </div>
  )
}

InfoButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
  },
  button: {
    'padding': '4px',
    '& svg': {
      position: 'static',
      margin: 0,
    },
  },
  popover: {
    'pointerEvents': 'none',
    '& .title': {
      display: 'block',
      padding: theme.spacing(1, 0),
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: '1px solid rgb(0, 0, 0, 0.1)',
    },
    '& .description': {
      display: 'block',
      padding: theme.spacing(1, 0),
      margin: 0,
      fontSize: '1em',
    },
  },
  paper: {
    maxWidth: '450px',
    padding: theme.spacing(0, 1),
  },
}))
