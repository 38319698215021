import { useQuery, UseQueryResult } from '@tanstack/react-query'
import {
  CreatePowerLink,
  CreatePowerLinkResponse,
  IPowerlink,
  PowerLinkParams,
  SearchRecipeParams,
  SearchRecipeResponse,
  SearchRecipesParams,
  SearchRecipesResponse,
  UpdatePowerlink,
} from '../@types/powerlinks'
import { mainApi as api } from './index'

const getPowerLinks = async ({
  limit,
  page,
  order,
  sort,
  blogId,
  searchTerm,
}: PowerLinkParams): Promise<IPowerlink> => {
  const params = new URLSearchParams()

  if (limit) params.append('limit', limit.toString())
  if (page) params.append('offset', page.toString())
  if (order) params.append('sortField', order)
  if (sort) params.append('sort', sort.toString().toUpperCase())
  if (blogId) params.append('blogId', blogId.toString())
  if (searchTerm) params.append('searchTerm', searchTerm)

  const response = await api.get<IPowerlink>('/shoppable-links/search-slinks', {
    params,
  })

  return response.data
}

const usePowerLinks = (props: PowerLinkParams): UseQueryResult<IPowerlink> => {
  const query = useQuery({
    queryKey: ['powerlinks', props],
    queryFn: () => getPowerLinks(props),
  })

  return query
}

const updatePowerlink = async (data: UpdatePowerlink) => {
  const response = await api.post('shoppable-links/update', data)

  return response.data
}

const createPowerLink = async (data: CreatePowerLink) => {
  const response = await api.post<CreatePowerLinkResponse>(
    'shoppable-links',
    data
  )

  return response.data
}

const getSearchRecipes = async (params: SearchRecipesParams) => {
  const response = await api.get<SearchRecipesResponse[]>(
    `shoppable-links/search-recipes`,
    {
      params,
    }
  )

  return response.data
}

const getSearchRecipe = async (
  params: SearchRecipeParams
): Promise<SearchRecipeResponse[]> => {
  const response = await api.get<SearchRecipeResponse[]>(
    `shoppable-links/search-recipe`,
    {
      params,
    }
  )

  return response.data
}

const useSearchRecipes = (
  params: SearchRecipesParams
): UseQueryResult<SearchRecipesResponse[]> => {
  const query = useQuery({
    queryKey: ['searchRecipes', params],
    queryFn: () => getSearchRecipes(params),
  })

  return query
}

const useSearchRecipe = (
  params: SearchRecipeParams
): UseQueryResult<SearchRecipeResponse[]> => {
  const query = useQuery({
    queryKey: ['searchRecipe', params],
    queryFn: () => getSearchRecipe(params),
  })

  return query
}

export {
  usePowerLinks,
  updatePowerlink,
  createPowerLink,
  useSearchRecipes,
  useSearchRecipe,
}
