import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import { CircularProgress, TextField } from '@material-ui/core'

import userApi from '@/services/user-api'

import { Form, Container } from './styles'

export default function AuthRequest() {
  const urlQuery = new URLSearchParams(window.location.search)
  const confirmationToken = encodeURIComponent(
    urlQuery.get('confirmationToken')
  )

  const [form, setForm] = useState('')
  const [state, setState] = useState('loading')
  const [error, setError] = useState(null)

  function sendAuthRequestCheck() {
    return userApi
      .get(
        `/auth/check-confirmation-token?confirmationToken=${confirmationToken}`
      )
      .then(({ data }) => {
        setForm({
          username: data.username,
          email: data.email,
        })

        setState(data)
      })
  }

  function checkPassword() {
    if (form.password === form.password2) {
      error?.target === 'password' && setError(null)
      return true
    } else {
      setError({
        message: 'As senhas devem ser iguais',
        target: 'password',
      })
      return false
    }
  }
  function checkForm() {
    if (!form?.username?.trim() || form?.username?.trim().length < 3) {
      setError({
        message: 'O nome deve conter pelo menos 3 letras',
        target: 'name',
      })
      return false
    } else if (!form?.password?.trim() || form?.password?.trim().length < 6) {
      setError({
        message: 'A senha deve conter pelo menos 6 caracteres',
        target: 'password',
      })
      return false
    } else if (!checkPassword()) {
      return false
    } else {
      setError(null)
      return true
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (checkForm()) {
      setState('loading')
      await userApi
        .patch(`/auth/active-analytics-user`, {
          username: form.username,
          password: form.password,
          confirmationToken,
        })
        .then(() => setState('created'))
        .catch(() => setState('error'))
    }
  }

  useEffect(() => {
    sendAuthRequestCheck().catch(() => setState('error'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Form>
        <div className="header">
          <h3 className="title">Recipe2basket</h3>
        </div>
        {state === 'loading' ? (
          <>
            <h4 className="accepted">Carregando</h4>
            <div style={{ flexGrow: 1 }} />
            <CircularProgress className="loader" />
          </>
        ) : state === 'created' ? (
          <>
            <h4 className="accepted">Sucesso</h4>
            <div style={{ flexGrow: 1 }} />
            <span>Seu cadastro no Recipe2basket Aaltytics foi concluido.</span>
            <button>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : state === 'error' ? (
          <>
            <h4 className="denied">Token Inválido</h4>
            <div style={{ flexGrow: 1 }} />
            <button style={{ margin: 'auto' }}>
              <NavLink to="/admin">ir para o painel</NavLink>
            </button>
          </>
        ) : (
          <>
            <h4 className="subtitle">Cadastro</h4>
            <div style={{ flexGrow: 1 }} />
            {error && <p className="error">{error.message}</p>}
            <TextField
              name="name"
              label="Nome"
              variant="outlined"
              value={form.username}
              error={error?.target === 'name'}
              onChange={e => setForm({ ...form, username: e.target.value })}
              size="small"
            />
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              value={form.email}
              error={error?.target === 'email'}
              size="small"
              disabled
            />
            <TextField
              name="password"
              label="Senha"
              variant="outlined"
              value={form.password}
              error={error?.target === 'password'}
              onChange={e => setForm({ ...form, password: e.target.value })}
              size="small"
              type="password"
            />
            <TextField
              name="password2"
              label="Repita sua senha"
              variant="outlined"
              value={form.password2}
              error={error?.target === 'password'}
              onChange={e => {
                setForm({ ...form, password2: e.target.value })
              }}
              size="small"
              type="password"
            />
            <button type="submit" onClick={handleSubmit}>
              Enviar
            </button>
          </>
        )}
        <div style={{ flexGrow: 1 }} />
      </Form>
    </Container>
  )
}
