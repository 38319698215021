// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
// core components
import styles from '@/assets/jss/material-dashboard-react/components/footerStyle.js'

const useStyles = makeStyles(styles)

export default function Footer() {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <p className={classes.right}>
          <span>
            &copy; {1900 + new Date().getYear()}{' '}
            <a
              href="https://recipe2basket.com"
              rel="noreferrer noopener"
              target="_blank"
              className={classes.a}
            >
              Zaply Shoppable Platform
            </a>
          </span>
        </p>
      </div>
    </footer>
  )
}
