import axios from 'axios'

import { getToken } from './auth'
import { MAIN_BASE_URL } from '@/config'

const mainApi = axios.create({ baseURL: MAIN_BASE_URL })

mainApi.interceptors.request.use(async config => {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export { mainApi }
