import { mainApi } from './api'

export const TOKEN_KEY = '@recipe2basket-Token'

export const haveAuthToken = () => localStorage.getItem(TOKEN_KEY) !== null

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const isAuthenticated = async () => {
  return await mainApi.get('/checksession').catch(_err => false)
}

export const getUserData = async () => {
  return await mainApi
    .get('/userdata')
    .catch(_err => false)
    .then(({ data }) => data)
}

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  document.cookie = '@r2b_si= ; expires = Thu, 01 Jan 1970 00:00:00 GMT'
  localStorage.removeItem(TOKEN_KEY)
}
