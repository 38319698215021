import { useEffect, useState } from 'react'
import {
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Button from '@/components/CustomButtons/Button.js'
import { Loader, TableTitle } from './styles'

import { usePartner } from '@/contexts/PartnerContext'
import userApi from '@/services/user-api'
import AddUserModal from './AddUserModal/AddUserModal'
import EditdUserModal from './EditdUserModal/EditdUserModal'
import { useMutation } from '@tanstack/react-query'
import { updateMonthlyReportUser } from '~/src/services/user'
import { useToast } from '~/src/contexts/Toast/context'

export default function UserManagement() {
  const { partner } = usePartner()
  const { showToast } = useToast()
  const [userList, setUserList] = useState(null)
  const [switchState, setSwitchState] = useState(false)
  const [switchStateReport, setSwitchStateReport] = useState(false)
  const [openAddUser, setOpenAddUser] = useState(false)
  const [openEditUser, setOpenEditUser] = useState(false)
  const [active, setActive] = useState(false)

  const { mutate } = useMutation({
    mutationFn: updateMonthlyReportUser,
    onSuccess: () => {
      fetchData()
      showToast({
        style: 'success',
        title: 'Sucesso',
        content: !active
          ? 'Reporte mensal desativado'
          : 'Reporte mensal ativado',
      })
      setSwitchStateReport(false)
    },
    onError: () => {
      showToast({
        style: 'error',
        title: 'Erro',
        content: 'Erro ao atualizar o status do do reporte mensal',
      })
      setSwitchStateReport(false)
    },
  })

  const fetchData = () => {
    userApi
      .get(`/users?blogId=${partner.id}&withAnalyticsLogs=true&logsLimit=1`)
      .then(({ data }) => {
        setUserList(data)
      })
      .catch(e => {
        throw new Error(e)
      })
  }

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner])

  async function switchUserState(blogId, userId, isActiveBlog) {
    setSwitchState(true)
    try {
      await userApi
        .patch(`/users`, { userId, blogId, isActiveBlog })
        .catch(e => {
          throw new Error(e)
        })

      const user = userList.find(user => user.id === userId)
      const userBlog = user.userBlogs.find(
        userBlog => userBlog.blog.id === blogId
      )
      userBlog.active = isActiveBlog
    } catch (err) {
      alert('Error on switch access user', err.toString())
    }

    setSwitchState(false)
  }

  function switchUserReport(id, monthlyReport) {
    setSwitchStateReport(true)
    setActive(!monthlyReport)
    mutate({
      blogId: partner.id,
      userId: id,
      monthlyReport: !monthlyReport,
    })
  }

  return (
    <>
      <Button
        style={{
          margin: '0px 10px 20px',
          position: 'fixed',
          top: 80,
          right: 20,
        }}
        variant="contained"
        color="success"
        onClick={event => {
          event.stopPropagation()
          setOpenAddUser(true)
        }}
      >
        Adicionar
      </Button>
      <TableTitle>Usuários</TableTitle>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: 30,
                }}
                align="center"
              >
                Id
              </TableCell>
              <TableCell
                style={{
                  width: 30,
                }}
                align="center"
              >
                Ativo
              </TableCell>
              <TableCell
                style={{
                  width: 140,
                }}
                align="center"
              >
                Reporte mensal
              </TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Usuário ativo</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell align="center">Último Login</TableCell>
              {/* <TableCell align="center">
                                Logins (90 dias)
                            </TableCell> */}
              <TableCell align="center">Informações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(userList) ? (
              userList.map(
                ({
                  id,
                  userBlogs,
                  username,
                  email,
                  logs,
                  active,
                  monthlyReport,
                }) => (
                  <TableRow key={id}>
                    <TableCell align="center">{id}</TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={!!userBlogs[0].active}
                        onChange={() => {
                          switchUserState(partner.id, id, !userBlogs[0].active)
                        }}
                        color="primary"
                        disabled={switchState}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        checked={monthlyReport}
                        onChange={e => {
                          e.preventDefault()
                          switchUserReport(id, monthlyReport)
                        }}
                        color="primary"
                        disabled={switchStateReport}
                      />
                    </TableCell>
                    <TableCell>{username}</TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{active ? 'sim' : 'não'}</TableCell>
                    <TableCell>
                      {userBlogs[0].manager ? 'Gestor' : 'Usuário'}
                    </TableCell>
                    <TableCell align="center">
                      {logs[0]
                        ? new Date(logs[0].createdAt).toLocaleDateString(
                            'pt-BR'
                          )
                        : '--/--/---'}
                    </TableCell>
                    <TableCell align="center">
                      <AssignmentIcon
                        onClick={event => {
                          event.stopPropagation()
                          setOpenEditUser(id)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <TableCell colSpan={10}>
                  <Loader />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AddUserModal
        open={openAddUser}
        setOpen={setOpenAddUser}
        blog={partner.id}
        fetchData={fetchData}
      />
      <EditdUserModal
        open={openEditUser}
        setOpen={setOpenEditUser}
        blog={partner.id}
      />
    </>
  )
}
