export const MAIN_API_URL = process.env.REACT_APP_API_URL as string

export const MAIN_BASE_URL = MAIN_API_URL.endsWith('/')
  ? MAIN_API_URL.slice(0, -1)
  : MAIN_API_URL

export const USER_API_URL = process.env.REACT_APP_API_URL_USERS as string

export const USER_BASE_URL = USER_API_URL.endsWith('/')
  ? USER_API_URL.slice(0, -1)
  : USER_API_URL
