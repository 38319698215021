import axios from 'axios'

import { getToken } from './auth'
import { USER_BASE_URL } from '@/config'

const userApi = axios.create({ baseURL: USER_BASE_URL })

userApi.interceptors.request.use(async config => {
  const token = getToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default userApi
