import React from 'react'
import { Switch, TablePagination } from '@material-ui/core'
import {
  TableResponsive,
  Table,
  TableBody,
  TableHead,
  TableBodyRow,
  TableHeadRow,
  TableHeadCell,
  TableCell,
  TableSortLabel,
} from './style'
import { ITable } from './types'
import { UpdatePowerlink } from '~/src/@types/powerlinks'
import { NavLink } from 'react-router-dom'

const TablePowerlink: React.FC<ITable> = props => {
  const {
    data,
    limit,
    total,
    page,
    sort,
    tableHeader,
    blogId,
    sortHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    handleUpdateStatusPowerlink,
  } = props

  function formatedDate(date: string) {
    if (!date) return ''

    const dateObj = new Date(date)
    dateObj.setHours(dateObj.getHours() - 6)

    return dateObj.toLocaleString('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC',
    })
  }

  function handleUpdatePowerlink(data: UpdatePowerlink) {
    handleUpdateStatusPowerlink(data)
  }

  return (
    <TableResponsive>
      <Table>
        <TableHead>
          <TableHeadRow>
            {tableHeader.map((header, index) => (
              <TableHeadCell key={index}>
                <TableSortLabel
                  active={sort === header.id}
                  direction={sort}
                  onClick={() => sortHandler(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data?.map((powerlink, index) => (
            <TableBodyRow key={index}>
              <TableCell style={{ maxWidth: '20rem' }}>
                {powerlink?.name?.replace(/\(inativo\)|\(ativo\)/g, '').trim()}
              </TableCell>
              <TableCell style={{ maxWidth: '15rem' }}>
                {powerlink?.url ? (
                  <NavLink
                    target="_blank"
                    to={powerlink?.url}
                    color="#000000de"
                  >
                    {powerlink?.url}
                  </NavLink>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell style={{ maxWidth: '10rem' }}>
                <NavLink
                  target="_blank"
                  to={`https://slink.zaply.com.br/${powerlink?.slug}`}
                  color="#000000de"
                >
                  https://slink.zaply.com.br/{powerlink?.slug}
                </NavLink>
              </TableCell>
              <TableCell>{formatedDate(powerlink.createdAt)}</TableCell>
              <TableCell>{formatedDate(powerlink?.updatedAt)}</TableCell>
              <TableCell>
                <Switch
                  value={powerlink?.active}
                  checked={powerlink?.active}
                  color="primary"
                  name="switchActive"
                  onChange={() => {
                    const data: UpdatePowerlink = {
                      id: powerlink?.id,
                      active: !powerlink?.active,
                      blogId: blogId,
                    }

                    handleUpdatePowerlink(data)
                  }}
                />
              </TableCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
      {!!data?.length && (
        <TablePagination
          labelRowsPerPage={'Exibir linhas'}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}-${to} de ${count}`
          }}
          component={'div'}
          page={page}
          count={total}
          rowsPerPage={limit}
          rowsPerPageOptions={
            window.innerWidth < 600 ? [] : [10, 20, 30, 40, 60, 80, 100]
          }
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableResponsive>
  )
}

export default TablePowerlink
