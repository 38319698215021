import Dashboard from '@material-ui/icons/Dashboard'
import AssessmentIcon from '@material-ui/icons/Assessment'
import LinkIcon from '@material-ui/icons/Link'
import ViewListIcon from '@material-ui/icons/ViewList'

export default [
  {
    index: true,
    path: '/recipe2basket',
    name: 'Recipe2Basket',
    icon: Dashboard,
    layout: '/admin',
    roles: [0, 1, 2, 3],
  },
  {
    path: '/shoppable-links',
    name: 'Shoppable Links',
    icon: Dashboard,
    layout: '/admin',
    roles: [0, 1, 2, 3],
    subRoutes: [
      {
        path: '/shoppable-links/create-shoppable-link',
        name: 'Criar Shoppable Link',
        icon: LinkIcon,
        layout: '/admin',
        roles: [0, 1, 2, 3],
      },
      {
        path: '/shoppable-links/links',
        name: 'Meus links',
        icon: ViewListIcon,
        layout: '/admin',
        roles: [0, 1, 2, 3],
      },
    ],
  },
  {
    path: '/user-management',
    name: 'Usuários',
    icon: AssessmentIcon,
    layout: '/admin',
    roles: [0],
  },
]
