import { ITableHeader, TableSort } from './types'

export const tableHeader: ITableHeader[] = [
  { id: 'name', label: ' Título', sort: true },
  { id: 'url', label: 'Url de origem', sort: false },
  { id: 'slug', label: 'Shoppable link', sort: false },
  {
    id: 'createdAt',
    label: 'Data de Criação',
    sort: true,
  },
  { id: 'updatedAt', label: 'Data de Atualização', sort: true },
  { id: 'active', label: 'Ativo', sort: true },
]

export const tableSort: TableSort[] = [
  { key: 'name', value: 'name' },
  { key: 'blog', value: 'blog' },
  { key: 'createdAt', value: 'created_at' },
  { key: 'updatedAt', value: 'updated_at' },
  { key: 'active', value: 'active' },
]
