import {
  defaultFont,
  container,
  grayColor,
} from '@/assets/jss/material-dashboard-react.js'

const footerStyle = {
  block: {
    color: 'inherit',
    // padding: '15px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
    ...defaultFont,
    fontWeight: '500',
    fontSize: '12px',
  },
  left: {
    float: 'left!important',
    display: 'block',
  },
  right: {
    padding: '5px 10px',
    margin: '0',
    fontSize: '14px',
    float: 'right!important',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid ' + grayColor[11],
    ...defaultFont,
    padding: '0',
  },
  container,
  a: {
    color: '#3c4858 !important',
    textDecoration: 'none !important',
    backgroundColor: 'transparent !important',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
  },
}
export default footerStyle
