/* eslint-disable react/prop-types */

import { useEffect, useState } from 'react'
import {
  CircularProgress,
  ClickAwayListener,
  Modal,
  Paper,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { Title, Body, Loader, TitleBar, DescField, BasicInfos } from './styles'

import { mainApi as api } from '@/services/api'

export default function EditdUserModal({ open, setOpen, blog }) {
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [userEvents, setUserEvents] = useState(null)

  useEffect(() => {
    open &&
      Promise.all([
        api.get(`userdata/${open}?blog=${blog}`).then(({ data }) => {
          setUser(data)
        }),
        api
          .post(`blog-users/event`, {
            query: 'summary',
            users: open,
            limit: 10,
          })
          .then(({ data }) => {
            if (data.length > 0) {
              setUserEvents(data[0].events)
            }
          }),
      ])
        .then(() => {
          setLoading(false)
        })
        .catch(console.log)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  // Handlers

  return (
    <Modal
      open={!!open}
      aria-labelledby="edit-user-title"
      aria-describedby="edit-user-body"
    >
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false)
        }}
      >
        <Paper
          style={{
            position: 'absolute',
            width: 'fit-content',
            maxWidth: '80%',
            minWidth: '50%',
            maxHeight: 'calc(100vh - 48px)',
            backgroundColor: 'white',
            padding: '10px',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            overflowY: 'auto',
          }}
        >
          <TitleBar id="edit-user-title">
            <Title>{loading ? 'Nome do Usuário' : user.name}</Title>
            <CloseIcon
              style={{
                position: 'absolute',
                right: '10',
                top: '10',
              }}
              onClick={() => {
                setLoading(true)
                setOpen(false)
              }}
            />
          </TitleBar>
          <Body id="edit-user-body">
            {loading ? (
              <Loader>
                <CircularProgress />
                <p>Carregando...</p>
              </Loader>
            ) : (
              <>
                <BasicInfos>
                  <Paper
                    style={{
                      padding: 15,
                      margin: 15,
                    }}
                  >
                    <DescField>
                      <b>Id:</b> {user.id}
                    </DescField>
                    <DescField>
                      <b>Email:</b> {user.email}
                    </DescField>
                    <DescField>
                      <b>News por email:</b>{' '}
                      {user.news ? 'Ativadas' : 'Desativadas'}
                    </DescField>
                    <DescField>
                      <b>Criado em:</b>{' '}
                      {new Date(user.createdAt).toLocaleDateString('pt-BR')}
                    </DescField>
                  </Paper>
                  <Paper
                    style={{
                      padding: 15,
                      margin: 15,
                    }}
                  >
                    <DescField>
                      <b>Blogs:</b>
                    </DescField>
                    {user.blogs.map((blog, index) => (
                      <DescField key={index}>{blog}</DescField>
                    ))}
                  </Paper>
                  <Paper
                    style={{
                      padding: 15,
                      margin: 15,
                    }}
                  >
                    <DescField>
                      <b>Manager</b>
                    </DescField>
                    <DescField>
                      <b>Id:</b> {user.manager.id}
                    </DescField>
                    <DescField>
                      <b>Nome:</b> {user.manager.name}
                    </DescField>
                    <DescField>
                      <b>Email:</b> {user.manager.email}
                    </DescField>
                  </Paper>
                </BasicInfos>
                {userEvents && (
                  <BasicInfos>
                    {userEvents.lastThreeLogins && (
                      <Paper
                        style={{
                          padding: 15,
                          margin: 15,
                        }}
                      >
                        <DescField>
                          <b>Últimos Logins</b>
                        </DescField>
                        {userEvents.lastThreeLogins.map(
                          ({ blog, date }, index) => (
                            <BasicInfos key={index}>
                              <DescField>
                                <b>Blog:</b> {blog}
                              </DescField>
                              <span
                                style={{
                                  width: 10,
                                }}
                              />
                              <DescField>
                                <b>Data:</b>{' '}
                                {new Date(date).toLocaleDateString('pt-BR')}
                              </DescField>
                            </BasicInfos>
                          )
                        )}
                      </Paper>
                    )}
                    {userEvents.lastThreeBlogViews && (
                      <Paper
                        style={{
                          padding: 15,
                          margin: 15,
                        }}
                      >
                        <DescField>
                          <b>Últimos Blogs Visualizados</b>
                        </DescField>
                        {userEvents.lastThreeBlogViews.map(
                          ({ blog, date }, index) => (
                            <BasicInfos key={index}>
                              <DescField>
                                <b>Blog:</b> {blog}
                              </DescField>
                              <span
                                style={{
                                  width: 10,
                                }}
                              />
                              <DescField>
                                <b>Data:</b>{' '}
                                {new Date(date).toLocaleDateString('pt-BR')}
                              </DescField>
                            </BasicInfos>
                          )
                        )}
                      </Paper>
                    )}
                    {userEvents.lastThreeExportData && (
                      <Paper
                        style={{
                          padding: 15,
                          margin: 15,
                        }}
                      >
                        <DescField>
                          <b>Últimos Dados Exportados</b>
                        </DescField>
                        {userEvents.lastThreeExportData.map(
                          ({ blog, date }, index) => (
                            <BasicInfos key={index}>
                              <DescField>
                                <b>Blog:</b> {blog}
                              </DescField>
                              <span
                                style={{
                                  width: 10,
                                }}
                              />
                              <DescField>
                                <b>Data:</b>{' '}
                                {new Date(date).toLocaleDateString('pt-BR')}
                              </DescField>
                            </BasicInfos>
                          )
                        )}
                      </Paper>
                    )}
                  </BasicInfos>
                )}
              </>
            )}
          </Body>
        </Paper>
      </ClickAwayListener>
    </Modal>
  )
}
