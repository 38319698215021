import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import EmailIcon from '@material-ui/icons/EmailRounded'

export const SentStep = ({ email, onClick }) => {
  const classes = useStyles()

  const handleClick = () => {
    onClick()
  }

  return (
    <>
      <div className={classes.icon}>
        <EmailIcon fontSize="large" color="inherit" />
      </div>

      <div className={classes.description}>
        <Typography component="span" variant="h6">
          Verifique sua caixa de entrada
        </Typography>
        <Typography color="textSecondary">
          Enviamos instruções de recuperação de senha ao e-mail{' '}
          <Typography component="span" color="primary">
            {email}
          </Typography>
          .
        </Typography>
      </div>

      <Button
        size="large"
        color="primary"
        variant="contained"
        className={classes.marginTop}
        onClick={handleClick}
        fullWidth
      >
        Entendi
      </Button>

      <div className={classes.marginTop}>
        <Typography variant="body2" color="textSecondary">
          <strong>Não recebeu o e-mail?</strong> Verifique sua caixa e filtros
          de spam ou tente novamente.
        </Typography>
      </div>
    </>
  )
}

SentStep.propTypes = {
  email: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

const useStyles = makeStyles(theme => ({
  icon: {
    textAlign: 'center',
    color: theme.palette.info.main,
  },
  description: {
    'textAlign': 'center',
    '& p': {
      marginTop: theme.spacing(1),
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))
