import { useEffect, useState, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { AxiosError } from 'axios'

import { mainApi as api } from '@/services/api'
import { login } from '@/services/auth'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'

import EmailIcon from '@material-ui/icons/EmailRounded'
import LockIcon from '@material-ui/icons/LockRounded'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

import { makeStyles } from '@material-ui/core/styles'

import { ZaplyLogo } from '@/assets/icons'

export default function SignIn() {
  const classes = useStyles()

  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  const [showPassword, setShowPassword] = useState(false)

  const [alert, setAlert] = useState({
    status: false,
    title: '',
    text: '',
  })

  const navigate = useNavigate()

  const handleClickShowPassword = () => {
    setShowPassword(prevState => !prevState)
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, { setSubmitting }) => {
      const payload = {
        email: values.email,
        password: values.password,
      }

      api
        .post('/sessions', payload)
        .then(response => {
          setSubmitting(false)

          if (!response?.data?.token?.token) {
            throw new Error('Token not found')
          }

          login(response.data.token.token)
          navigate('/admin/recipe2basket')
        })
        .catch(handleSubmitErrors)
    },
  })

  const handleSubmitErrors = error => {
    if (!(error instanceof AxiosError)) return

    const errors = {
      401: handleUnauthorizedError,
      unknown: handleUnknownError,
    }

    if (error.response?.status in errors) {
      errors[error.response.status]()
      return
    }

    errors['unknown']()
  }

  const handleUnauthorizedError = () => {
    setAlert({
      status: true,
      title: 'E-mail ou senha inválidos',
      text: 'Não conseguimos realizar o login com os dados fornecidos.',
    })
  }

  const handleUnknownError = () => {
    setAlert({
      status: true,
      title: 'Erro inesperado',
      text: 'Falha ao realizar o login, tente novamente.',
    })
  }

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.logo}>
          <ZaplyLogo />
        </div>

        <Paper classes={{ root: classes.content }}>
          <div className={classes.description}>
            <Typography component="span" variant="h6">
              Bem-vindo(a) de volta
            </Typography>
            <Typography color="textSecondary">
              Digite suas credenciais para acessar o painel do Analytics.
            </Typography>
          </div>

          <form
            noValidate
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            className={classes.marginTop}
          >
            <FormControl
              fullWidth
              margin="none"
              error={!!formik.touched.email && !!formik.errors.email}
            >
              <InputLabel variant="outlined">E-mail</InputLabel>
              <OutlinedInput
                {...formik.getFieldProps('email')}
                inputRef={emailRef}
                type="email"
                label="E-mail"
                variant="outlined"
                onChange={event => {
                  formik.getFieldProps('email').onChange(event)
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Icon aria-hidden color="primary">
                      <EmailIcon />
                    </Icon>
                  </InputAdornment>
                }
              />
              {!!formik.touched.email && !!formik.errors.email && (
                <FormHelperText error>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              margin="none"
              className={classes.marginTop}
              error={!!formik.touched.password && !!formik.errors.password}
            >
              <InputLabel variant="outlined">Senha</InputLabel>
              <OutlinedInput
                {...formik.getFieldProps('password')}
                inputRef={passwordRef}
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                variant="outlined"
                startAdornment={
                  <InputAdornment position="start">
                    <Icon aria-hidden color="primary">
                      <LockIcon />
                    </Icon>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPassword ? 'Ocultar a senha' : 'Exibir a senha'
                      }
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {!!formik.touched.password && !!formik.errors.password && (
                <FormHelperText error>{formik.errors.password}</FormHelperText>
              )}
            </FormControl>

            <Button
              fullWidth
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              className={classes.marginTop}
              disabled={!formik.isValid || formik.isSubmitting}
              startIcon={
                formik.isSubmitting && (
                  <CircularProgress size="1em" color="inherit" />
                )
              }
            >
              Entrar
            </Button>
          </form>

          {!!alert.status && (
            <Alert
              severity="error"
              closeText="Fechar"
              onClose={() =>
                setAlert({
                  status: false,
                  alert: '',
                  text: '',
                })
              }
              className={classes.marginTop}
            >
              {!!alert.title && <AlertTitle>{alert.title}</AlertTitle>}
              {alert.text}
            </Alert>
          )}
        </Paper>

        <div className={classes.recovery}>
          <Typography component="span">Esqueceu sua senha?</Typography>
          <Link to="/recovery" component={NavLink}>
            Redefinir senha
          </Link>
        </div>
      </div>
    </div>
  )
}

const noLeadingOrTrailingSpacesPattern = /^(?!\s).+(?<!\s)$/

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Digite um endereço de e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .required('A senha é obrigatória')
    .min(4, 'A senha deve ter ao menos 4 caracteres')
    .matches(
      noLeadingOrTrailingSpacesPattern,
      'A senha não deve conter espaço no início ou final'
    ),
})

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: theme.palette.grey[100],
    overflow: 'auto',
  },
  inner: {
    width: '100%',
    maxWidth: '420px',
  },
  logo: {
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > svg': {
      height: '42px',
    },
  },
  content: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  description: {
    textAlign: 'center',
  },
  recovery: {
    'textAlign': 'center',
    '& > a': {
      marginLeft: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}))
