import styled from 'styled-components'
import { TextField, Select as SelectMui } from '@material-ui/core'

const Fieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  max-width: 400px;
  width: 100%;
  margin-bottom: 10px;
`

const Input = styled(TextField)`
  max-width: 400px;
  width: 100%;
`
const Select = styled(SelectMui)`
  max-width: 400px;
  width: 100%;
`

const ContainerIngredients = styled.div``

export { Input, Select, Fieldset, ContainerIngredients }
