import { mainApi } from './api'

const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export default async function sendEvent({ type, message, blog }) {
  const si = getCookie('@r2b_si')

  await mainApi
    .get(
      `blog-users/event/?type=${type}${message ? `&message=${message}` : ''}${
        blog ? `&blog=${blog}` : ''
      }${si ? `&si=${si}` : ''}`
    )
    .then(({ data }) => {
      document.cookie = `@r2b_si=${data}; path=/;`
    })
    .catch(e => {
      throw new Error(e)
    })
}
