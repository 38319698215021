/* eslint-disable react/prop-types */

import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

const styles = _theme => ({
  root: {
    padding: '0.9375rem 20px',
  },
  paginationLocal: {
    margin: '0 20px',
  },
})

const useStyles = makeStyles(styles)

export default function Pagination(props) {
  const classes = useStyles()
  const { limit, setLimit, offset, setOffset, hasNextPage } = props.options

  const handleChangeResults = async event => {
    const value = event.target.value
    setOffset(0)
    setLimit(value)
  }

  const handlePaginationPrev = async () => {
    const paginationOffset = offset - limit
    setOffset(paginationOffset >= 0 ? paginationOffset : 0)
  }

  const handlePaginationNext = async () => {
    const paginationOffset = limit + offset
    setOffset(paginationOffset)
  }

  return (
    <Grid
      container
      direction="row"
      alignContent="center"
      justifyContent="flex-end"
      className={classes.root}
    >
      <FormControl style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
        <InputLabel id="pagination-label">Exibir linhas</InputLabel>
        <Select
          labelId="pagination-label"
          value={limit}
          onChange={event => handleChangeResults(event)}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
        <div className={classes.paginationLocal}>
          {offset + 1} - {offset + limit}
        </div>
        <Button
          disabled={offset <= 0}
          onClick={_event => handlePaginationPrev()}
          value={limit}
        >
          <ChevronLeft />
        </Button>
        <Button
          disabled={!hasNextPage}
          onClick={_event => handlePaginationNext()}
          value={limit}
        >
          <ChevronRight />
        </Button>
      </FormControl>
    </Grid>
  )
}
