/* eslint-disable react/prop-types */

import {
  CircularProgress,
  ClickAwayListener,
  FormHelperText,
  Modal,
  Paper,
  TextField,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import Button from '@/components/CustomButtons/Button.js'
import { Title, Body, Error, Loader } from './styles'
import { mainApi as api } from '@/services/api'
import userApi from '../../../services/user-api'

export default function AddUserModal({ open, setOpen, blog, fetchData }) {
  const [loading, setLoading] = useState(true)
  const [, setManagersList] = useState(null)
  const [form, setForm] = useState({ name: '', email: '', isManager: 0 })

  useEffect(() => {
    if (open) {
      api
        .get(`/blog-users/list/?blog=${blog}&manager=1`)
        .then(({ data }) => {
          setManagersList(
            data.map(manager => ({
              ...manager,
              selected: false,
            }))
          )
          setLoading(false)
        })
        .catch(e => {
          throw new Error(e)
        })
    } else {
      setManagersList(null)
      setLoading(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  // Handlers

  async function handleSubmit() {
    if (checkForm()) {
      try {
        await userApi.post('/auth/signup-analytics', {
          username: form.name,
          email: form.email.trim(),
          blogs: [{ id: blog }],
        })
        fetchData()
        setOpen(false)
      } catch (error) {
        console.log(error.response.data)
        setError({ message: error.response.data.message })
      }
    }
  }

  const [error, setError] = useState(null)
  function checkForm() {
    if (!form?.name?.trim() || form?.name?.trim().length < 3) {
      setError({
        message: 'O nome deve conter pelo menos 3 letras',
        target: 'name',
      })
      return false
    } else if (!form?.email?.trim() || form?.email?.trim().length < 3) {
      setError({
        message: 'O email deve ser preenchido',
        target: 'email',
      })
      return false
    } else {
      return true
    }
  }

  return (
    <Modal
      open={open}
      aria-labelledby="add-user-title"
      aria-describedby="add-user-body"
    >
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false)
        }}
      >
        <Paper
          style={{
            position: 'absolute',
            width: 400,
            maxHeight: 'calc(100vh - 48px)',
            backgroundColor: 'white',
            padding: '10px',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%)`,
            overflowY: 'auto',
          }}
        >
          <Title id="add-user-title">Adicionar Usuário</Title>
          <Body id="add-user-body">
            {loading ? (
              <Loader>
                <CircularProgress />
                <p>Carregando...</p>
              </Loader>
            ) : (
              <>
                {error && <Error>{error?.message}</Error>}
                <TextField
                  name="name"
                  label="Nome"
                  variant="outlined"
                  value={form.name}
                  error={error?.target === 'name'}
                  onChange={e =>
                    setForm({
                      ...form,
                      name: e.target.value,
                    })
                  }
                />
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={form.email}
                  error={error?.target === 'email'}
                  onChange={e =>
                    setForm({
                      ...form,
                      email: e.target.value,
                    })
                  }
                />
                <FormHelperText
                  style={{
                    margin: '0 0 10px 32px',
                  }}
                >
                  Um email será enviado para o usuário criar sua senha e
                  completar o cadastro
                </FormHelperText>
                <Button
                  style={{ margin: '0px auto' }}
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  Adicionar
                </Button>
              </>
            )}
          </Body>
        </Paper>
      </ClickAwayListener>
    </Modal>
  )
}
