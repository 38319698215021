import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from './app'

import '@/assets/css/material-dashboard-react.css'
import '@/styles/global.css'

const selector = '#root'
const container = document.querySelector(selector)

if (!container) {
  throw new Error(
    `Root container not found in the document, make sure the root container in the "index.html" file has the "${selector}" selector`
  )
}

const root = createRoot(container)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
