import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { Routes } from './routes'

import { createMuiTheme } from '@material-ui/core/styles'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './services'
import { ToastProvider } from './contexts/Toast/context'

const theme = createMuiTheme()

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <CssBaseline />
        <Routes />
      </ToastProvider>
    </ThemeProvider>
  </QueryClientProvider>
)
