import styled, { keyframes } from 'styled-components'

const shine = keyframes`
    100% {
        background-position-x: -200%
    }
`

export const Loader = styled.div`
  width: 100%;
  height: 45px;
  margin: 2.5px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(110deg, #efefef 8%, #ffffff 18%, #efefef 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s ${shine} linear infinite;
`
export const TableTitle = styled.h4`
  margin: 10px 10px 0;
`
