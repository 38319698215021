import { mainApi as api } from '../services/api'
import moment from 'moment'

const parseRecipeList = ({ data, isApp }) => {
  return data[0].map(r => (isApp ? r.title : r.url))
}

const queryParamsString = params =>
  Object.keys(params)
    .map(
      (obj, i) => params[obj] && (i === 0 ? '?' : '&') + obj + '=' + params[obj]
    )
    .filter(param => param)
    .join('')

const getEvents = async options => {
  const queryParams = queryParamsString(options)

  try {
    const url = '/events' + queryParams
    const { data } = await api.get(url)

    if (!data.events[0].length) throw new Error('Sem dados para essa pesquisa.')
    const fields = [
      'date',
      'button_opened',
      'supermarket_click',
      'leads',
      'lead_val',
    ]
    const dataCsv = [fields]
    data.events[0].forEach(e => {
      dataCsv.push([fields.map(f => e[f])])
    })

    return { data, dataCsv }
  } catch (err) {
    throw new Error(err.message)
  }
}

const getRecipesList = async ({ partnerUrl, isApp, productType }) => {
  let url = `/recipes?blog=${partnerUrl}&isApp=${isApp}`

  if (productType) {
    url += `&productType=${productType}`
  }

  const { data } = await api.get(url)

  return parseRecipeList({
    data,
    isApp,
  })
}

const filterDateEvents = (data, date) =>
  data.filter(d => new Date(d.date).getDate() + 1 === date)

const recipeEventsParse = ({ data, partner }) =>
  data.map(d => [
    partner.is_app ? d.title : d.url.toLocaleString('pt-br'),
    d.button_loaded.toLocaleString('pt-br'),
    d.button_opened.toLocaleString('pt-br'),
    d.supermarket_click.toLocaleString('pt-br'),
    d.leads.toLocaleString('pt-br'),
    (d.leads_by_openings || 0).toFixed(1) + '%',
    (d.lead_val || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'brl',
    }),
    (d.avg_cart_val || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'brl',
    }),
  ])

const recipeEventsParseWithoutPageview = ({ data, partner }) =>
  data.map(d => [
    partner.is_app ? d.title : d.url.toLocaleString('pt-br'),
    d.button_opened.toLocaleString('pt-br'),
    d.supermarket_click.toLocaleString('pt-br'),
    d.leads.toLocaleString('pt-br'),
    (d.lead_val || 0).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'brl',
    }),
  ])

const supermarketsEventsParse = ({ data }) =>
  data
    .filter(d => d.supermarket !== 'null' && d.supermarket)
    .map(d => [
      d.supermarket.toLocaleString('pt-br'),
      d.supermarket_click.toLocaleString('pt-br'),
      d.leads.toLocaleString('pt-br'),
      (d.leads_by_supermarket || 0).toFixed(1) + '%',
      (d.lead_val || 0).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'brl',
      }),
    ])

const totals = data =>
  data.reduce((a, b) => {
    return {
      button_loaded: a.button_loaded + b.button_loaded || 0,
      button_opened: a.button_opened + b.button_opened || 0,
      buy_click: a.buy_click + b.buy_click || 0,
      product_click: a.product_click + b.product_click || 0,
      supermarket_click: a.supermarket_click + b.supermarket_click || 0,
      leads: a.leads + b.leads || 0,
      lead_val: a.lead_val + b.lead_val || 0,
      avg_cart_val: a.avg_cart_val + b.avg_cart_val || 0,
    }
  })

const pagination = async (options, partner) => {
  let groupBy = ''
  let parser = null

  try {
    if (options.name === 'recipeEvents') {
      groupBy = 'recipe'
      parser = recipeEventsParse
    } else if (options.name === 'supermarketsEvents') {
      groupBy = 'supermarket'
      parser = supermarketsEventsParse
    } else {
      return false
    }

    const data = await getEvents({ ...options, groupBy })

    data.data[0] = parser({
      data: data.data[0],
      partner,
    })

    return data
  } catch (err) {
    console.log(err)
  }
}

const parseDateDesc = (initDate, endDate) =>
  `de ${initDate.getDate()}/${
    initDate.getMonth() + 1
  }/${initDate.getFullYear()} até ${endDate.getDate()}/${
    endDate.getMonth() + 1
  }/${endDate.getFullYear()}`

const changeEventsDate = async (range, options) => {
  try {
    const date = new Date()
    let initDate = options.initDate || new Date()
    const endDate = options.endDate || new Date()
    let timeDesc = ''
    let eventGroup = 'date'

    if (range === 'mes') {
      initDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 30
      )
      timeDesc = 'últimos 30 dias'
    } else if (range === 'ontem') {
      date.setHours(0, 0, 0)
      initDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 1
      )
      timeDesc = 'ontem'
      eventGroup = 'hour'
    } else if (range === 'hoje') {
      date.setHours(0, 0, 0)
      initDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
      timeDesc = 'hoje'
      eventGroup = 'hour'
    } else if (range === 'semana') {
      initDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 7
      )
      timeDesc = 'últimos 7 dias'
    } else if (range === 'trimestre') {
      initDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 90
      )
      timeDesc = 'últimos 90 dias'
    } else if (range === 'custom') {
      timeDesc = parseDateDesc(initDate, endDate)
      const daysDiff = moment(initDate).diff(moment(endDate, 'days'))

      if (daysDiff <= 1) {
        eventGroup = 'hour'
      } else {
        eventGroup = 'date'
      }
    }

    const { data, dataCsv } = await getEvents({
      ...options,
      initDate,
      endDate,
      groupBy: eventGroup,
      orderBy: eventGroup === 'hour' ? 'hours' : 'date',
    })

    const recipesEvents = await getEvents({
      ...options,
      initDate,
      endDate,
      groupBy: 'recipe',
      orderBy: 'leads',
      sort: 'desc',
      limit: options.recipeListLimit,
    })

    const supermarketsEvents = await getEvents({
      ...options,
      initDate,
      endDate,
      groupBy: 'supermarket',
      orderBy: 'leads',
      sort: 'desc',
      limit: options.supermarketListLimit,
    })

    return {
      data,
      dataCsv,
      supermarketsEvents,
      recipesEvents,
      timeDesc,
      eventGroup,
      dates: { initDate, endDate },
      customDate: parseDateDesc(initDate, endDate),
    }
  } catch (err) {
    return err
  }
}

export const createMonthDates = month => {
  const date = new Date()

  return {
    initDate: moment({
      year: date.getFullYear(),
      month,
      date: 1,
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).toDate(),
    endDate: moment({
      year: date.getFullYear(),
      month,
      date: new Date(date.getFullYear(), month + 1, 0).getDate(),
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    }).toDate(),
  }
}

const parseRangeToDates = (range, options) => {
  try {
    const dateNow = new Date()

    let initDate = options.initDate || new Date()
    let endDate = options.endDate || new Date()

    let timeDesc = ''
    let eventGroup = 'date'

    if (range === 'today') {
      const date = moment(dateNow)

      initDate = date
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate()
      endDate = date
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toDate()

      timeDesc = 'Hoje'
      eventGroup = 'hour'
    } else if (range === 'yesterday') {
      const date = moment(dateNow)

      initDate = date
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, 'd')
        .toDate()
      endDate = date
        .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        .toDate()

      timeDesc = 'Ontem'
      eventGroup = 'hour'
    } else if (range === 'custom') {
      const dates = {
        now: moment(dateNow),
        init: moment(initDate).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }),
        end: moment(endDate),
      }

      if (dates.now.format('YYYYMMDD') === dates.end.format('YYYYMMDD')) {
        dates.end = dates.now
      } else {
        dates.end.set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0,
        })
      }

      initDate = dates.init.toDate()
      endDate = dates.end.toDate()

      timeDesc = parseDateDesc(dates.init.toDate(), dates.end.toDate())

      const daysDiff = dates.end.diff(dates.init, 'days')

      if (daysDiff < 1) {
        eventGroup = 'hour'
      }
    } else if (range === 'jan') {
      const date = createMonthDates(0)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Janeiro / ${dateNow.getFullYear()}`
    } else if (range === 'feb') {
      const date = createMonthDates(1)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Fevereiro / ${dateNow.getFullYear()}`
    } else if (range === 'mar') {
      const date = createMonthDates(2)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Março / ${dateNow.getFullYear()}`
    } else if (range === 'apr') {
      const date = createMonthDates(3)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Abril / ${dateNow.getFullYear()}`
    } else if (range === 'may') {
      const date = createMonthDates(4)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Maio / ${dateNow.getFullYear()}`
    } else if (range === 'jun') {
      const date = createMonthDates(5)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Junho / ${dateNow.getFullYear()}`
    } else if (range === 'jul') {
      const date = createMonthDates(6)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Julho / ${dateNow.getFullYear()}`
    } else if (range === 'aug') {
      const date = createMonthDates(7)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Agosto / ${dateNow.getFullYear()}`
    } else if (range === 'sep') {
      const date = createMonthDates(8)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Setembro / ${dateNow.getFullYear()}`
    } else if (range === 'oct') {
      const date = createMonthDates(9)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Outubro / ${dateNow.getFullYear()}`
    } else if (range === 'nov') {
      const date = createMonthDates(10)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Novembro / ${dateNow.getFullYear()}`
    } else if (range === 'dec') {
      const date = createMonthDates(11)
      initDate = date.initDate
      endDate = date.endDate
      timeDesc = `Dezembro / ${dateNow.getFullYear()}`
    }

    return { initDate, endDate, timeDesc, eventGroup }
  } catch (err) {
    console.log(err)
  }
}

const changeTableResults = async (list, limit, options) => {
  let groupBy = ''
  let parser = null

  try {
    if (list === 'recipesEvents') {
      groupBy = 'recipe'
      parser = recipeEventsParseWithoutPageview
    } else if (list === 'supermarketsEvents') {
      groupBy = 'supermarket'
      parser = supermarketsEventsParse
    }

    const data = await getEvents({ ...options, groupBy, limit })

    return { data, parser }
  } catch (err) {
    console.log(err)
  }
}

const getPercentageVariance = (x, y) => (x / y - 1) * 100

const parseEventsToCsvData = events =>
  events.map(({ date, button_opened, supermarket_click, leads, lead_val }) => ({
    date,
    button_opened,
    supermarket_click,
    leads,
    lead_val,
  }))

const getProductsData = async options => {
  const response = await api.post('/events/ingredients-branded', options)

  return response.data
}

const getProductsTotalValues = async options => {
  const response = await api.post(
    '/events/ingredients-branded/total-value',
    options
  )

  return response.data
}

const getTotalProducts = async options => {
  const response = await api.post(
    '/events/ingredients-branded/total-quantity',
    options
  )

  return response.data
}

export {
  getEvents,
  getRecipesList,
  getProductsData,
  getProductsTotalValues,
  getTotalProducts,
  filterDateEvents,
  recipeEventsParse,
  recipeEventsParseWithoutPageview,
  supermarketsEventsParse,
  totals,
  pagination,
  changeEventsDate,
  changeTableResults,
  getPercentageVariance,
  parseRangeToDates,
  parseEventsToCsvData,
}
