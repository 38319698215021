import moment from 'moment'

export const previusMonthInit = monthsToSubtract =>
  moment().subtract(monthsToSubtract, 'month').startOf('month')

export const previusMonthLastDay = previusMonth =>
  previusMonth.clone().endOf('month')

export const getLastDays = (days, date) =>
  moment(date)
    .subtract(days, 'days')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

export const getMomentDate = date => moment(date)

export function currentMonth() {
  const date = new Date()
  return moment(date).month()
}

export function monthAbbr(month) {
  return moment().month(month).format('MMM')
}
