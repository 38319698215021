const addZeroInOneDigitNumber = n =>
  parseInt(n).toString().length === 1
    ? 0 + parseInt(n).toString()
    : parseInt(n).toString()

const parseDataDay = (data, field, fieldName) => {
  if (!data) return []
  return data.map(e => {
    const event = {}
    event['name'] =
      addZeroInOneDigitNumber(new Date(e.date).getUTCDate()) +
      '/' +
      addZeroInOneDigitNumber(new Date(e.date).getUTCMonth() + 1)
    event[fieldName] = e[field]

    return event
  })
}

const parseDataHour = (data, field, fieldName) => {
  if (!data) return []

  return data.map(e => {
    const event = {}
    event['name'] = e.hours + 'h'
    event[fieldName] = e[field]

    return event
  })
}

const funnelData = data => {
  if (!data) return []

  const dataParsed = []

  dataParsed.push({
    name: 'Interações de compras',
    value: data.button_opened,
    fill: '#8884d8',
    rowSize: 80,
    taxesName: 'Taxa aberturas',
    taxesValue: data.button_opened.toFixed(1) + '%',
  })

  dataParsed.push({
    name: 'Varejista selecionado',
    value: data.supermarket_click,
    fill: '#869DF0',
    rowSize: 60,
    taxesName: 'Taxa de cliques ver lista/aberturas',
    taxesValue:
      ((data.supermarket_click / data.button_opened) * 100).toFixed(1) + '%',
  })

  dataParsed.push({
    name: 'Carrinhos de compra',
    value: data.leads,
    fill: '#81B1E6',
    rowSize: 40,
    taxesName: 'Taxa de leads/abertura',
    taxesValue: ((data.leads / data.button_opened) * 100).toFixed(1) + '%',
  })

  return dataParsed
}

export { parseDataDay, parseDataHour, funnelData }
