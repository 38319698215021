import styled from 'styled-components'
import { AccordioItemPorps } from './types'

const Container = styled.div``

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 15px;
`

const AccordionItem = styled.div<AccordioItemPorps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: center;
  background-color: ${props => (props.active ? '#00acc1' : 'transparent')};
  padding: ${props => (props.index > 0 ? '10px 15px 10px 35px' : '10px 15px')};
  color: #ffffff;
  font-weight: 300;
  line-height: 30px;
  cursor: pointer;

  .icon-arrow {
    margin-top: 2px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
      margin: 0 0 0 15px;
    }
  }
`

export { Container, AccordionContainer, AccordionItem }
