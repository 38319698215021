import styled from 'styled-components'
import {
  Table as TableMui,
  TableHead as TableHeadMui,
  TableBody as TableBodyMui,
  TableRow as TableRowMui,
  TableCell as TableCellMui,
  TableSortLabel as TableSortLabelMui,
  withStyles,
  Box,
} from '@material-ui/core'

const TableSortLabel = withStyles({
  active: {
    color: 'rgb(76, 175, 80)!important',
  },
})(TableSortLabelMui)

const Table = styled(TableMui)`
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
`

const TableHead = styled(TableHeadMui)``

const TableBody = styled(TableBodyMui)``

const TableHeadCell = styled(TableCellMui)`
  color: #6ebe71;
  font-size: 0.8em;
`

const TableCell = styled(TableCellMui)`
  line-height: 1.42857143;
  padding: 12px 8px;
  vertical-align: middle;
  font-size: 0.8125rem;
`

const TableResponsive = styled.div`
  width: 100%;
  margin-top: 15px;
  overflow-x: auto;
`

const TableHeadRow = styled(TableRowMui)`
  height: 56px;
  color: inherit;
  display: table-row;
  outline: none;
  vertical-align: middle;
`

const TableBodyRow = styled(TableRowMui)`
  height: 48px;
  color: inherit;
  display: table-row;
  outline: none;
  vertical-align: middle;
`

const FilterContainer = styled(Box)`
  display: flex;
  align-items: baseline;
  gap: 15px;
`

export {
  Table,
  TableHead,
  TableBody,
  TableHeadCell,
  TableCell,
  TableResponsive,
  TableHeadRow,
  TableBodyRow,
  TableSortLabel,
  FilterContainer,
}
