import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: auto;
`

export const Form = styled.form`
  min-width: 350px;
  max-width: 350px;
  max-height: 70%;
  min-height: 480px;
  box-shadow: #0000000d 5px 5px 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-bottom: 200px;
  margin-top: 200px;

  @media screen and (max-width: 450px) {
    max-width: 90%;
  }

  .header {
    min-height: 75px;
    max-height: 75px;
    background-color: #727cf5;
    border-radius: 5px 5px 0 0;
  }
  .title {
    color: white;
    font-weight: bold;
    text-align: center;
  }
  .accepted {
    padding: 10px;
    color: white;
    background: #0acf97;
    font-weight: bold;
    text-align: center;
    border: 0;
    border-radius: 5px;
    width: 90%;
    margin: 10px auto;
  }
  .denied {
    padding: 10px;
    color: white;
    background: #ea4141;
    font-weight: bold;
    text-align: center;
    border: 0;
    border-radius: 5px;
    width: 90%;
    margin: 10px auto;
  }
  textarea {
    flex: 1;
    min-height: 100px;
    max-height: 100px;
    margin: 0 auto;
    padding: 10px;
    color: #777;
    font-size: 14.4px;
    width: 80%;
    border: 1px solid #ddd;
    border-radius: 8px;
    resize: none;
    &::placeholder {
      color: #999;
    }
  }
  button {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    background: #0acf97;
    height: 56px;
    border: 0;
    border-radius: 5px;
    width: 60%;
    margin: 20px auto;
  }
  a {
    color: white !important;
    text-decoration: none !important;
  }
  span {
    margin: 10px 20px;
    text-align: center;
  }
  .loader {
    margin: 30px auto;
  }
`
