import React from 'react'
import { usePartner } from '@/contexts/PartnerContext'
import { FormControl, Grid, MenuItem, Select } from '@material-ui/core'

const BlogsMenu = () => {
  const { appLoading, partner, changePartner, allPartners } = usePartner()
  return (
    <Grid item xs={3}>
      <FormControl style={{ width: '100%' }}>
        {!appLoading && (
          <Select
            value={partner.id}
            onChange={e => changePartner(e.target.value)}
            displayEmpty
          >
            {allPartners
              .filter(p => !p.is_test)
              .map((p, i) => (
                <MenuItem key={i} value={p.id}>
                  {p.name}
                </MenuItem>
              ))}
          </Select>
        )}
      </FormControl>
    </Grid>
  )
}

export default BlogsMenu
