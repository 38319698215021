export function jsonToCsv({ headers, content, eol = '\n', separator = ',' }) {
  const parseBody = value => {
    if (typeof value === 'string' && value.includes(separator)) {
      return `"${value}"`
    }
    return value
  }

  return (
    headers.join(separator).concat(eol) +
    content.map(i => i.map(parseBody).join(separator)).join(eol)
  )
}
