import { makeStyles } from '@material-ui/core/styles'

export const useUtilityStyles = makeStyles(_theme => ({
  flex: {
    display: 'flex',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  alignCenter: {
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}))
