/* eslint-disable react/prop-types */

import { createContext, useContext, useEffect, useState } from 'react'
import { haveAuthToken } from '@/services/auth'
import sendEvent from '@/services/events'
// import { getUserData } from '@/services/auth'

import { mainApi as api } from '../services/api'

export const PartnerContext = createContext()

export const usePartner = () => {
  const context = useContext(PartnerContext)

  return context
}

const filterPartners = value => !value.is_test

export const PartnerProvider = ({ children }) => {
  const [partner, setPartner] = useState({})
  const [allPartners, setAllPartners] = useState([])

  const [appLoading, setAppLoading] = useState(false)

  const getBlogs = async () => {
    if (!haveAuthToken()) return
    try {
      setAppLoading(true)
      const { data } = await api.get('blog-users/')
      setAllPartners(data?.filter(filterPartners))
      const chosenPartner = data?.find(
        partner =>
          `${partner.id}` ===
          localStorage.getItem('@recipe2basket-selectedBlog')
      )

      setPartner(chosenPartner || data[0])
      sendEvent({
        type: 'login',
        blog: chosenPartner?.id || data[0]?.id,
      })
    } catch (err) {
      console.error(err)
    } finally {
      setAppLoading(false)
    }
  }

  const changePartner = async partnerId => {
    if (!haveAuthToken()) return
    setAppLoading(true)
    const { data } = await api.get('blog-users/')
    setAllPartners(data?.filter(filterPartners))
    const chosenPartner = allPartners.find(partner => partner.id === partnerId)
    setPartner(chosenPartner)

    sendEvent({
      type: 'viewblog',
      blog: chosenPartner?.id,
    })
    localStorage.setItem('@recipe2basket-selectedBlog', partnerId)
    setAppLoading(false)
  }

  useEffect(() => {
    getBlogs()
  }, [])
  return (
    <PartnerContext.Provider
      value={{
        getBlogs,
        partner,
        changePartner,
        allPartners,
        appLoading,
      }}
    >
      {children}
    </PartnerContext.Provider>
  )
}
